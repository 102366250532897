import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Routes, Link } from 'react-router-dom';
import HomePage from './homePage';
import ShopPage from './shop';
import SearchPage from './search';
import BrandBuildPage from './brandBuild';
import DiscoverAuthenticityPage from './discoverAuthenticity';
import AboutPage from './aboutPage';
import TermsPage from './termsPage';
import PrivacyPage from './privacyPage';
import PremiumPage from './premiumPage';
import ScrollToTop from './scrollToTop';
import PorticoLogo from './assets/porticoLogoV101.png';
import { FaBars } from 'react-icons/fa';

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = {
    parentContainer: {
      width: '100%',
      margin: 0,
      backgroundColor: 'black',
    },
    porticoLogo: {
      height: 30,
      width: 'auto',
      padding: '10px'
    },
    pageContainer: {
      backgroundColor: '#1A1A1A',
    },
    tabsHeader: {
      listStyle: 'none', 
      flexDirection: 'row',
      position: 'fixed', 
      top: 0,  
      width: '100%',  
      margin: 0,
      // padding: isMobile ? '10px' : '20px',
      padding: isMobile ? '10px 0px' : '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: isMobile ? 'space-between' : 'auto',
      gap: '20px',
      backgroundColor: 'transparent',  //black 
      zIndex: 1000,  
      
    },

    tabsFooterParent: {
      margin: 0,
      display: 'flex',
      alignItems: isMobile ? 'left' : 'center',
      justifyContent: isMobile ? 'left' : 'center',
      backgroundColor: 'black',
    },
    tabsFooter: {
      padding: '20px',
      display: 'flex',
      alignItems: isMobile ? 'left' : 'center',
      justifyContent: isMobile ? 'left' : 'center',
      width: '100%',
    },
    footerColumns: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: isMobile ? 'left' : 'center',
      width: '100%',
      rowGap: '15px',
    },
    tabsTextBold: {
      textDecoration: 'none',
      color: 'white',
      textAlign: isMobile ? 'left' : 'center',
      fontWeight: '800',
    },
    tabsText: {
      textDecoration: 'none',
      textAlign: isMobile ? 'left' : 'center',
      color: 'white',
    },

    menuIcon: {
      color: 'white',
      fontSize: '24px',
      padding: '10px',
    },
    menu: {
      display: menuOpen ? 'flex' : 'none',
      flexDirection: 'column', // Ensure items are stacked vertically
      position: 'absolute',
      top: '60px',
      padding: '10px',
      backgroundColor: '#1a1a1a',
      width: '100%',
      textAlign: 'center',
    },
    menuItem: {
      padding: '10px',
      color: 'white',
      textDecoration: 'none',
      listStyle: 'none',
    },
  };


  return (
    <Router>
      <div style={styles.parentContainer}>
        <nav style={styles.tabsHeader}>
          <img src={PorticoLogo} style={styles.porticoLogo} alt='portico-logo' />
          {isMobile ? (
            <FaBars style={styles.menuIcon} onClick={toggleMenu} />
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Link style={styles.menuItem} to="/">Home</Link>
              <Link style={styles.menuItem} to="/premium">Premium</Link>
            </div>
          )}
          {isMobile && (
            <div style={styles.menu}>
              <Link style={styles.menuItem} to="/" onClick={() => setMenuOpen(false)}>Home</Link>
              <Link style={styles.menuItem} to="/searchandshop" onClick={() => setMenuOpen(false)}>Shop with Portico</Link>
              <Link style={styles.menuItem} to="/discover" onClick={() => setMenuOpen(false)}>Portico Discovery</Link>
              <Link style={styles.menuItem} to="/premium" onClick={() => setMenuOpen(false)}>Premium</Link>
              <Link style={styles.menuItem} to="/terms" onClick={() => setMenuOpen(false)}>Terms</Link>
              <Link style={styles.menuItem} to="/privacy" onClick={() => setMenuOpen(false)}>Privacy Policy</Link>
            </div>
          )}
        </nav>
        
        <ScrollToTop />
        <div style={styles.pageContainer}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/shop" element={<ShopPage />} />
            <Route path="/buildyourbrand" element={<BrandBuildPage />} />
            <Route path="/searchandshop" element={<SearchPage />} />
            <Route path="/discover" element={<DiscoverAuthenticityPage />} />
            <Route path="/premium" element={<PremiumPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
          </Routes>

          <nav>
            <div style={styles.tabsFooterParent}>
              <ul style={styles.tabsFooter} >
                <div style={styles.footerColumns}>
                  <li>
                    <Link style={styles.tabsTextBold} to="/">Home</Link>
                  </li>
                  <li>
                    <Link style={styles.tabsTextBold} to="/shop">Shop with Portico</Link>
                  </li>
                  <li>
                    <Link style={styles.tabsTextBold} to="/buildyourbrand">Build Your Brand</Link>
                  </li>
                  <li>
                    <Link style={styles.tabsTextBold} to="/premium">Premium</Link>
                  </li>
                  <li>
                    <Link style={styles.tabsTextBold} to="/terms">Terms</Link>
                  </li>
                  <li>
                    <Link style={styles.tabsTextBold} to="/privacy">Privacy Policy</Link>
                  </li>
                  <div>
                    <text style={styles.tabsText}>One Portico, LLC </text>
                  </div>
                  <div>
                    <text style={styles.tabsText}>All Rights Reserved, 2024</text>
                  </div>
                  <div>
                    <text style={styles.tabsTextBold} >Contact: </text>
                    <text style={styles.tabsText}>portico@oneportico.com</text>
                  </div>
                  <div>
                    <text style={styles.tabsTextBold} >Support: </text>
                    <text style={styles.tabsText}>support@oneportico.com</text>
                  </div>
                </div>
              </ul>
            </div>
          </nav>

        </div>
      </div>
    </Router>
  );
}

export default App;






















// OG APP.js with OG header code:

// import React, { useState, useEffect } from 'react';
// import { HashRouter as Router, Route, Routes, Link } from 'react-router-dom';
// import HomePage from './homePage';
// import ShopPage from './shop';
// import SearchPage from './search';
// import BrandBuildPage from './brandBuild';
// import DiscoverAuthenticityPage from './discoverAuthenticity';
// import AboutPage from './aboutPage';
// import TermsPage from './termsPage';
// import PrivacyPage from './privacyPage';
// import PremiumPage from './premiumPage';
// import ScrollToTop from './scrollToTop';

// // import PorticoLogo from './assets/porticoLogo75.png';
// import PorticoLogo from './assets/porticoLogoV101.png';

// function App() {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   const styles ={
//     parentContainer: {
//       width: '100%',
//       margin: 0,
//       backgroundColor: 'black', //1A1A1A
//     },
//     porticoLogo: {
//       height: 30,
//       width: 'auto',
//     },
//     pageContainer: {
//       backgroundColor: '#1A1A1A', //
//     },
//     tabsHeader: {
//       margin: 0,
//       // padding: 20,
//       padding: isMobile ? 10 : 20,
//       display: 'flex',
//       // justifyContent: 'left',
//       alignItems: 'center',
//       gap: 20,
//       backgroundColor: 'black', //1A1A1A
//     },
//     tabsFooterParent: {
//       // width: '100%',
//       margin: 0,
//       display: 'flex',
//       alignItems: isMobile ? 'left' : 'center', //'left',
//       justifyContent: isMobile ? 'left' : 'center', //'left',
//       alignConent: isMobile ? 'left' : 'center',
//       // gap: 20,
//       backgroundColor: 'black', //1A1A1A
//     },
//     tabsFooter: {
//       padding: 20,
//       // gap: 20,
//       display: 'flex',
//       alignItems: isMobile ? 'left' : 'center',//'left',
//       justifyContent: isMobile ? 'left' : 'center',
//       alignConent: isMobile ? 'left' : 'center',
//       width: '100%',
//       // backgroundColor: 'red',
//       justifyContent: isMobile ? 'left' : 'center', //left
//     },
//     footerColumns: {
//       display: 'flex',
//       flexDirection: isMobile ? 'column' : 'column', // Adjust based on isMobile
//       justifyContent: isMobile ? 'left' : 'center',
//       alignConent: isMobile ? 'left' : 'center',
//       // backgroundColor: 'red',
//       width: '100%',
//       rowGap: 15,
//     },
  
//     tabsTextBold: {
//       textDecoration: 'none',
//       color: 'white',
//       textAlign: isMobile ? 'left' : 'center',
//       fontWeight: '600',
//     },
//     tabsText: {
//       textDecoration: 'none',
//       textAlign: isMobile ? 'left' : 'center',
//       color: 'white',
//     },
//   };

//   return (
//     <Router>
//       <div style={styles.parentContainer}>
//         <nav>
//           <ul style={styles.tabsHeader} >
//             <img src={PorticoLogo} style={styles.porticoLogo} alt='portico-logo' />
//             <li>
//               <Link style={styles.tabsTextBold} to="/">Home</Link>
//             </li>
//             <li>
//               <Link style={styles.tabsTextBold} to="/premium">Premium</Link>
//             </li>
//           </ul>
//         </nav>

      
//         <ScrollToTop />
//         <div style={styles.parentContainer}>
//           <Routes>
//             <Route path="/" element={<HomePage />} />
//             <Route path="/shop" element={<ShopPage />} />
//             <Route path="/buildyourbrand" element={<BrandBuildPage />} />
//             <Route path="/searchandshop" element={<SearchPage />} />
//             <Route path="/discover" element={<DiscoverAuthenticityPage />} />
//             <Route path="/premium" element={<PremiumPage />} />
//             <Route path="/about" element={<AboutPage />} />
//             <Route path="/terms" element={<TermsPage />} />
//             <Route path="/privacy" element={<PrivacyPage />} />
//           </Routes>
//         </div>
        


        // <nav>
        //   <div style={styles.tabsFooterParent}>
        //     <ul style={styles.tabsFooter} >
        //       <div style={styles.footerColumns}>
        //         <li>
        //           <Link style={styles.tabsTextBold} to="/">Home</Link>
        //         </li>
        //         <li>
        //           <Link style={styles.tabsTextBold} to="/shop">Shop with Portico</Link>
        //         </li>
        //         <li>
        //           <Link style={styles.tabsTextBold} to="/buildyourbrand">Build Your Brand</Link>
        //         </li>
        //         <li>
        //           <Link style={styles.tabsTextBold} to="/premium">Premium</Link>
        //         </li>
        //         <li>
        //           <Link style={styles.tabsTextBold} to="/terms">Terms</Link>
        //         </li>
        //         <li>
        //           <Link style={styles.tabsTextBold} to="/privacy">Privacy Policy</Link>
        //         </li>
        //         <div>
        //           <text style={styles.tabsText}>One Portico, LLC </text>
        //         </div>
        //         <div>
        //           <text style={styles.tabsText}>All Rights Reserved, 2024</text>
        //         </div>
        //         <div>
        //           <text style={styles.tabsTextBold} >Contact: </text>
        //           <text style={styles.tabsText}>portico@oneportico.com</text>
        //         </div>
        //         <div>
        //           <text style={styles.tabsTextBold} >Support: </text>
        //           <text style={styles.tabsText}>support@oneportico.com</text>
        //         </div>
        //       </div>
        //     </ul>
        //   </div>
        // </nav>
//       </div>

//     </Router>
//   );
// }

// export default App;