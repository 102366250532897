import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import HomeBackground1 from './assets/homeBackground1.png';

function HomePage() {
  const getResponsiveStyles = () => {
    const isMobile = window.innerWidth <= 768;

    const baseStyles = {
      parentContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
      },
      sectionContainer1: {
        display: 'flex',
        // flexDirection: isMobile ? 'column' : 'row',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        backgroundColor: '#1a1a1a', //white //1a1a1a
        backgroundImage: `url(${HomeBackground1})`,
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
      },
      sectionContainer2: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        alignItems: 'center',
        backgroundColor: '#1A1A1A',
      },
      sectionContainer3: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        alignItems: 'center',
        backgroundColor: '#1A1A1A',
      },
      subsectionContainer: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: isMobile ? '100%' : '75%',
        backgroundColor: '#1A1A1A',
      },
      subsectionTitle: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: isMobile ? '100%' : '75%',
        backgroundColor: '#1A1A1A',
      },
      svgContainer: {
        width: '100%',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      },
      svgIcons: {
        width: isMobile ? '10%' : '10%',
        margin: 0,
        padding: 0,
      },
      largeImages: {
        width: '100%', 
        objectFit: 'cover',
        // marginBottom: 20,
      },
      largeImages2: {
        width: isMobile ? '100%' : '100%',
        height: 'auto',
      },
      buttonParentContainer: {
        width: '100%',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px 0',
      },
      motionImage1: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        backgroundColor: 'transparent', //white //1a1a1a
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
      },
      appStoreButtonParentContainer: {
        position: 'absolute',
        bottom: isMobile ? '50px' : '50px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: isMobile ? 'auto' : '100%',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 3,
        cursor: 'pointer',
      },
      // appStoreButtonParentContainerBottom: {
      //   position: 'relative',
      //   bottom: isMobile ? '50px' : '50px',
      //   left: '50%',
      //   transform: 'translateX(-50%)',
      //   width: isMobile ? 'auto' : '100%',
      //   display: 'flex',
      //   alignContent: 'center',
      //   alignItems: 'center',
      //   justifyContent: 'center',
      //   zIndex: 3,
      //   cursor: 'pointer',
      // },
      buttonParent: {},
      containerButton: {
        backgroundColor: '#FF8C00',
        borderRadius: 50,
        borderWidth: 0,
        padding: "10px 50px 10px 50px",
        cursor: 'pointer',
      },

      containerImage: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        order: isMobile ? -1 : 1,
      },
      containerImageSection1: {
        width: isMobile ? 'auto' : '40%',
        height: isMobile ? 'auto' : '70vh',
        position: 'static', 
      },
      containerText: {
        flex: 1,
        rowGap: isMobile ? '12px' : '25px',
        display: 'flex',
        flexDirection: 'column',
        padding: isMobile ? '20px' : '40px',
        fontFamily: 'Impact, Charcoal, sans-serif',
        order: isMobile ? 0 : 1,
      },
      containerTextSection1: {
        flex: 1,
        rowGap: isMobile ? '12px' : '25px',
        display: 'flex',
        flexDirection: 'column',
        padding: isMobile ? '20px' : '40px',
        paddingTop: isMobile ? 70 : 70,
        fontFamily: 'Impact, Charcoal, sans-serif',
        zIndex: 2,
        // order: isMobile ? 0 : 1,
      },
      containerText2: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
        flexDirection: 'column',
        padding: isMobile ? '20px' : '40px',
        fontFamily: 'Impact, Charcoal, sans-serif',
        order: isMobile ? 0 : 1,
        // order: isMobile ? 1 : 0, //image first
      },

      textImpactWhite1: {
        color: 'white',
        fontSize: isMobile ? 45 : 110,
        textAlign: 'center',
        fontFamily: 'Impact, Charcoal, sans-serif', 
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      textImpactBlack1: {
        color: '#1A1A1A',
        fontSize: isMobile ? 45 : 110,
        textAlign: 'center',
        fontFamily: 'Impact, Charcoal, sans-serif', 
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      textImpactWhite2: {
        color: 'white',
        fontSize: isMobile ? 30 : 90,
        textAlign: 'center',
        fontFamily: 'Impact, Charcoal, sans-serif',
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      textImpactBlack2: {
        color: '#1a1a1a',
        fontSize: isMobile ? 30 : 90,
        textAlign: 'center',
        fontFamily: 'Impact, Charcoal, sans-serif',
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      textWhiteHeader: {
        color: 'white',
        fontFamily: 'sans-serif',
        fontSize: isMobile ? 15 : 25,
        fontWeight: '500',
        margin: 0,
        textAlign: 'center',
      },
      textWhiteHeader2: {
        color: 'white',
        fontFamily: 'sans-serif',
        fontSize: isMobile ? 15 : 25,
        fontWeight: '500',
        margin: 0,
        padding: 0,
        textAlign: 'center',
      },
      textBlackHeader: {
        color: 'black',
        fontFamily: 'sans-serif',
        fontSize: isMobile ? 15 : 25,
        fontWeight: '500',
        margin: 0,
        textAlign: 'center',
      },
      textWhiteLarge: {
        color: 'white',
        fontFamily: 'sans-serif',
        // fontSize: isMobile ? 25 : 40,
        // fontSize: isMobile ? 20 : 35,
        fontSize: isMobile ? 15 : 30,
        fontWeight: '800',
        margin: 0,
      },
      textWhite: {
        color: 'white',
        fontFamily: 'sans-serif',
        // fontSize: isMobile ? 15 : 25,
        fontSize: isMobile ? 15 : 20,
        fontWeight: '100',
        margin: 0,
      },
      textBlack: {
        color: 'black',
        fontFamily: 'sans-serif',
        fontSize: isMobile ? 15 : 25,
        fontWeight: '500',
        margin: 0,
      },
      buttonTextWhite: {
        color: 'white',
        width: '100%',
        fontFamily: 'sans-serif',
        fontSize: isMobile ? 15 : 20,
        fontWeight: '600',
        margin: 0,
      },

      sectionContainer20: {
        // paddingTop: isMobile ? '100px' : '150px', 
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        alignItems: 'center',
        backgroundColor: '#1a1a1a', //f2f2f2 //1A1A1A
      },
      containerText20: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
        flexDirection: 'column',
        padding: isMobile ? '20px' : '40px',
        fontFamily: 'Impact, Charcoal, sans-serif',
        // order: isMobile ? 0 : 1, // Same logic applied to this text container as well
      },
      subsectionContainer20: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        // width: '100%',
        width: isMobile ? '80%' : '100%',
      },
      sectionContainer201: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        // width: '100%',
        width: isMobile ? '80%' : '100%',
        backgroundColor: '#393838'
      },
      textImpactWhite20: {
        color: 'white',
        // fontSize: isMobile ? 30 : 90, //fontSize: isMobile ? 30 : 45,
        fontSize: isMobile ? 30 : 60, //fontSize: isMobile ? 30 : 45,
        textAlign: 'left', //center
        fontFamily: 'Impact, Charcoal, sans-serif',
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      textImpactWhite20C: {
        color: 'white',
        // fontSize: isMobile ? 30 : 90, //fontSize: isMobile ? 30 : 45,
        fontSize: isMobile ? 30 : 60, //fontSize: isMobile ? 30 : 45,
        textAlign: 'center', //center
        fontFamily: 'Impact, Charcoal, sans-serif',
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      sectionContainer30: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        alignItems: 'center',
        backgroundColor: '#1A1A1A', //1A1A1A //f2f2f2 // //162A4A
      },
      containerImage30: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
      },
      containerText30: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
        flexDirection: 'column',
        padding: isMobile ? '20px' : '40px',
        fontFamily: 'Impact, Charcoal, sans-serif',
      },
      subsectionContainer0: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: isMobile ? '100%' : '75%',
        // backgroundColor: 'white', //1a1a1a //f2f2f2
        borderRadius: isMobile ? 5 : 10,
        padding: isMobile ? 10 : 15, //padding: isMobile ? 10 : 50,
      },
    };

    return baseStyles;
  };

  const styles = getResponsiveStyles();

  // const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });

  const imageVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } }
  };

  return (


    <div style={styles.parentContainer} >
      <div style={styles.sectionContainer1} >
        <div style={styles.containerTextSection1} >
          <h1 style={styles.textImpactWhite1}>PORTICO</h1>
          {/* <p style={styles.textWhiteHeader}>Shop with direction.</p> */}
          <div>
            <p style={styles.textWhiteHeader2}>Search with purpose.</p>
            <p style={styles.textWhiteHeader2}>Find what matters.</p>
          </div>
        </div>

        {/* <p style={styles.textWhite}>Portico takes the risk out of the buying process by making product research more convenient. Join the growing community of savvy shoppers who are using a new method of search to make better decisions.</p> */}
        <motion.div style={styles.motionImage1} initial="hidden" animate="visible" variants={imageVariants}>
          <div style={styles.containerImageSection1} >
            <motion.img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/homeImage1.png" alt='topImage' style={styles.largeImages} />
            <div style={styles.appStoreButtonParentContainer}>
              <div style={styles.buttonParent}>
                <a href="https://apps.apple.com/us/app/portico-shopping-portal/id6477725753" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                  <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/appStoreBadgeBlack.svg" alt='topImage' style={styles.largeImages} />
                </a>
              </div>
            </div>
          </div>
        </motion.div>
      </div>

        {/* <div style={styles.sectionContainer30} >
          <div style={styles.containerImage30} >
            <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/searchImage2.png" alt='ShopImage2' style={styles.largeImages} />
          </div>
          <div style={styles.containerText30} >
            <div style={styles.subsectionContainer0}>
              <h1 style={styles.textImpactWhite20}>EVERYTHING,</h1>
              <h1 style={styles.textImpactWhite20}>EVERYWHERE, ALL IN ONE</h1>
              
              <p style={styles.textWhiteLarge}>Search by Store</p>
              <p style={styles.textWhite}>Don't shop using 20 tabs and don't bookmark dozens of websites. Make your product research and acquistion more convenient.</p>
              <p style={styles.textWhiteLarge}>Sort by Price</p>
              <p style={styles.textWhite}>Traditional search engines aren't built to help you shop, they are designed to deliver you advertising. When you search with Portico, you get answers not ads.</p>
              <p style={styles.textWhiteLarge}>Discover New Products</p>
              <p style={styles.textWhite}>Portico detects features of a product and finds you high-quality knock-offs and budget-friendly alternatives.</p> 
            </div>
          </div>
        </div> */}
 
      <motion.div ref={ref2} style={styles.sectionContainer2} initial="hidden" animate={inView2 ? "visible" : "hidden"} variants={imageVariants}>
        <div style={styles.containerText2} >
          <div style={styles.subsectionContainer}>
            {/* <h1 style={styles.textImpactWhite20}>FIND WHAT'S NEW, FIND WHAT'S NEAR, & FIND WHAT'S NEXT</h1> */}
            <h1 style={styles.textImpactWhite20}>DISCOVER WITH EASE</h1>

            {/* <p style={styles.textWhiteLarge}>Find What's New</p> */}
            <p style={styles.textWhite}>It's never been easier to buy online, but investing in quality has never been more challenging.</p>
            <p style={styles.textWhite}>When shopping for the latest style, Portico makes it easier to spend your money on decent products.</p>
            {/* <p style={styles.textWhiteLarge}>Find What's Near</p>
            <p style={styles.textWhite}>Ordering online isn't always the cheapest or the most convenient way to shop.</p>
            <p style={styles.textWhite}>When time is of the essense, Portico's predictive product system lets you see which stores carry what you need before you leave your house. When you search Portico, you can get what you need faster than buying it online without running all over town.</p>
            <p style={styles.textWhiteLarge}>Find What's Next</p>
            <p style={styles.textWhite}>Looking to keep up with the latest trends? Smart Search automatically finds you the same or similar product for a different price at a different store by searching across the web using the image and description.</p>
            <p style={styles.textWhite}>Let Portico help you shop for used versions so that you can be trendy and sustainable.</p> */}
            <div style={styles.buttonParentContainer}>
              <div style={styles.buttonParent}>
                <Link to="/searchandshop" style={{ textDecoration: 'none' }}>
                  <button style={styles.containerButton}>
                    <p style={styles.buttonTextWhite}>Learn More</p>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div style={styles.containerImage} >
          {/* <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/homeImage2.png" alt='ShopImage' style={styles.largeImages} /> */}
          {/* <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/findSimilarImage.png" alt='ShopImage' style={styles.largeImages} /> */}
          <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/discoverWithEase.png" alt='ShopImage' style={styles.largeImages} />
        </div>
      </motion.div>

      <div style={styles.sectionContainer20} >
        <div style={styles.containerText20} >
          <div style={styles.subsectionContainer20}>
            <h1 style={styles.textImpactWhite20C}>SHOP EVERYTHING, EVERYWHERE, ALL IN ONE</h1>
          </div>
        </div>
      </div> 
      <div style={styles.sectionContainer30} >
        <div style={styles.containerImage30} >
          <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/promo5Image.png" alt='ShopImage2' style={styles.largeImages} />
        </div>
      </div>

      <motion.div ref={ref3} style={styles.sectionContainer3} initial="hidden" animate={inView3 ? "visible" : "hidden"} variants={imageVariants}>
        <div style={styles.containerImage} >
          <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/socialImage.png" alt='ConnectImage' style={styles.largeImages} />
        </div>
        <div style={styles.containerText2} >
          <div style={styles.subsectionContainer}>
            {/* <div style={styles.svgContainer} >
              <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/launch.svg" alt='pioneerYourBrand' style={styles.svgIcons} />
            </div> */}
            <h1 style={styles.textImpactWhite20}>GET TIPS, GIVE TIPS</h1>
            {/* <p style={styles.textWhiteLarge}>Connect in a New Way</p> */}
            {/* <p style={styles.textWhite}>The internet is full of astroturfed conversations from anonymous strangers trying to mislead you.</p> */}
            <p style={styles.textWhite}>It has never been easier to get recommendations, learn novel tips and tricks, and see what's good. Portico supplements the shopping experience with an engaged community that is dedicated to authentic reviews and candid recommendations.</p>
            <p style={styles.textWhite}>Be more informed and share lists, products, images, and links with your community.</p>
            {/* <p style={styles.textWhiteLarge}>Create Smart Lists</p>
            <p style={styles.textWhite}>Creating your shopping lists with the notes app? Planning what to buy with a list can keep you focused and help you save money.</p>
            <p style={styles.textWhite}>Portico Lists were designed to make it easier to save while you shop. Compile products and items into one list and easily use Portico's Smart Search to discover similar products and easily identify the best place to buy.</p>
            <p style={styles.textWhiteLarge}>Collaborate with Friends</p>
            <p style={styles.textWhite}>Create Smart Lists and conveniently share them with your friends to plan a prodject, a party, or a trip. With Portico, it has never been easier to put your thoughts together in one place and share your plans with the world.</p>
             */}
            <div style={styles.buttonParentContainer}>
              <div style={styles.buttonParent}>
                <Link to="/discover" style={{ textDecoration: 'none' }}>
                  <button style={styles.containerButton}>
                    <p style={styles.buttonTextWhite}>Learn More</p>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* <div>
        <div style={styles.sectionContainer20} >
          <div style={styles.containerText20} >
            <div style={styles.subsectionContainer20}>
              <h1 style={styles.textImpactWhite20C}>SHOP SMARTER</h1>
              <h1 style={styles.textImpactWhite20C}>DO CONVENIENT PRODUCT RESEARCH</h1>
              <h1 style={styles.textImpactWhite20C}>DOWNLOAD PORTICO</h1>
            </div>
          </div>
        </div> 
        <div style={styles.appStoreButtonParentContainerBottom}>
          <div style={styles.buttonParent}>
            <a href="https://apps.apple.com/us/app/portico-shopping-portal/id6477725753" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/appStoreBadgeBlack.svg" alt='topImage' style={styles.largeImages} />
            </a>
          </div>
        </div>
      </div> */}

    </div>
  );
}

export default HomePage;














// import React from 'react';
// import { Link } from 'react-router-dom';

// function HomePage() {
//   // Define a function to dynamically change styles based on screen width
//   const getResponsiveStyles = () => {
//     const isMobile = window.innerWidth <= 768; // Example breakpoint at 768px

//     const baseStyles = {
//       parentContainer: {
//         display: 'flex',
//         flexDirection: 'column',
//         width: '100%',
//         height: '100%',
//         backgroundColor: 'black',
//       },
//       sectionContainer1: {
//         display: 'flex',
//         flexDirection: isMobile ? 'column' : 'row',
//         width: '100%',
//         alignItems: 'center',
//         backgroundColor: '#1a1a1a', //1a1a1a //F2F2F2 //01457B //002E4D
//         backgroundSize: 'cover', 
//         backgroundPosition: 'center',
//       },
//       sectionContainer2: {
//         display: 'flex',
//         flexDirection: isMobile ? 'column' : 'row',
//         width: '100%',
//         alignItems: 'center',
//         backgroundColor: '#1A1A1A', //backgroundColor: isMobile ? '#1A1A1A' : '#f2f2f2', //f2f2f2 //1A1A1A
//       },
//       sectionContainer3: {
//         display: 'flex',
//         flexDirection: isMobile ? 'column' : 'row',
//         width: '100%',
//         alignItems: 'center',
//         backgroundColor: '#1A1A1A', // backgroundColor: isMobile ? '#1A1A1A' : '#f2f2f2', //f2f2f2 //162A4A
//       },
//       subsectionContainer: {
//         flex: 1,
//         rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
//         display: 'flex',
//         flexDirection: 'column',
//         height: '100%',
//         width: isMobile ? '100%' : '75%', //width: '100%',
//         backgroundColor: '#1A1A1A',// backgroundColor: isMobile ? '#1A1A1A' : '#f2f2f2', //backgroundColor: '#f2f2f2',
//         // borderRadius: isMobile ? 5 : 10,
//         // padding: isMobile ? 10 : 15,
//         // margin: 20,
//       },
//       svgContainer: {
//         width: '100%',
//         display: 'flex',
//         alignContent: 'center',
//         alignItems: 'center',
//         justifyContent: 'center',
//       },
//       svgIcons: {
//         width: isMobile ? '10%' : '10%',
//         margin: 0,
//         padding: 0,
//         // backgroundColor: 'red'
//       },
//       largeImages: {
//         width: isMobile ? '100%' : '100%', 
//         height: 'auto',
//       },
//       largeImages2: {
//         width: isMobile ? '100%' : '100%',
//         height: 'auto',
//       },
//       buttonParentContainer: {
//         width: '100%',
//         display: 'flex',
//         alignContent: 'center',
//         alignItems: 'center',
//         justifyContent: 'center',
//         padding: '20px 0', //5px 0
//       },
//       buttonParent: {
//         // width: '50%',
//       },
//       containerButton: {
//         backgroundColor: '#FF8C00',
//         borderRadius: 50, //10
//         borderWidth: 0,
//         padding: "10px 50px 10px 50px",
//       },

//       containerImage: {
//         flex: 1,
//         display: 'flex',
//         justifyContent: 'center',
//         order: isMobile ? -1 : 1, // Image before text in mobile view
//       },
//       containerText: {
//         flex: 1,
//         rowGap: isMobile ? '12px' : '25px',
//         display: 'flex',
//         flexDirection: 'column',
//         padding: isMobile ? '20px' : '40px',
//         fontFamily: 'Impact, Charcoal, sans-serif',
//         order: isMobile ? 0 : 1, // Text after image in desktop view
//       },
//       containerText2: {
//         flex: 1,
//         rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         alignContent: 'center',
//         height: '100%',
//         flexDirection: 'column',
//         padding: isMobile ? '20px' : '40px',
//         fontFamily: 'Impact, Charcoal, sans-serif',
//         order: isMobile ? 0 : 1, // Same logic applied to this text container as well
//       },

//       textImpactWhite1: {
//         color: 'white',
//         fontSize: isMobile ? 45 : 110, //fontSize: isMobile ? 30 : 110,
//         textAlign: 'center',
//         fontFamily: 'Impact, Charcoal, sans-serif', 
//         transform: 'skewX(-20deg)', 
//         margin: 0,
//       },
//       textImpactBlack1: {
//         color: '#1A1A1A',
//         fontSize: isMobile ? 30 : 110,
//         fontFamily: 'Impact, Charcoal, sans-serif', 
//         transform: 'skewX(-20deg)', 
//         margin: 0,
//       },
//       textImpactWhite2: {
//         color: 'white',
//         fontSize: isMobile ? 30 : 90, //fontSize: isMobile ? 30 : 45,
//         textAlign: 'center',
//         fontFamily: 'Impact, Charcoal, sans-serif',
//         transform: 'skewX(-20deg)', 
//         margin: 0,
//       },
//       textImpactBlack2: {
//         color: '#1a1a1a', // color: '#1a1a1a',// color: isMobile ? '#f2f2f2' : '#1a1a1a', // color: '#1a1a1a',
//         fontSize: isMobile ? 30 : 90, //fontSize: isMobile ? 30 : 45,
//         textAlign: 'center',
//         fontFamily: 'Impact, Charcoal, sans-serif',
//         transform: 'skewX(-20deg)', 
//         margin: 0,
//       },
//       textWhiteHeader: {
//         color: 'white',
//         fontFamily: 'sans-serif',
//         fontSize: isMobile ? 15 : 25,
//         fontWeight: '500',
//         margin: 0,
//         textAlign: 'center',
//       },
//       textWhite: {
//         color: 'white',
//         fontFamily: 'sans-serif',
//         fontSize: isMobile ? 15 : 25,
//         fontWeight: '500',
//         margin: 0,
//       },
//       textBlack: {
//         color: '#1a1a1a',  //color: isMobile ? '#f2f2f2' : '#1a1a1a',
//         fontFamily: 'sans-serif',
//         fontSize: isMobile ? 15 : 25,
//         fontWeight: '500',
//         margin: 0,
//       },
//       buttonTextWhite: {
//         color: 'white',
//         width: '100%',
//         fontFamily: 'sans-serif',
//         // fontSize: 20, //20
//         fontSize: isMobile ? 15 : 20,
//         fontWeight: '600',
//         margin: 0,
//       }
//     };

//     return baseStyles;
//   };

//   const styles = getResponsiveStyles();

//   return (
//     <div style={styles.parentContainer} >

//       <div style={styles.sectionContainer1} >
//           <div style={styles.containerImage} >
//             <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/homeImage1.png" alt='topImage' style={styles.largeImages} />
//           </div>
//           <div style={styles.containerText} >
//             <h1 style={styles.textImpactWhite1}>PORTICO</h1>
//             {/* <p style={styles.textWhiteHeader}>Download Portico and join the ultimate online shopping experience.</p> */}
//             <p style={styles.textWhiteHeader}>Download Portico and start shopping smarter.</p>
//             <div style={styles.buttonParentContainer}>
//               <div style={styles.buttonParent}>
//                 <a href="https://apps.apple.com/us/app/portico-shopping-portal/id6477725753" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
//                   {/* <img src={AppStoreBadgeBlack} alt="Download from the App Store" /> */}
//                   <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/appStoreBadgeBlack.svg" alt='topImage' style={styles.largeImages} />
//                 </a>
//               </div>
//             </div>
//             {/* <p style={styles.textWhite}>Say goodbye to endless searching, price comparison hassles, and the fear of overpaying. Join the growing community of savvy shoppers who've transformed their online shopping and selling experience with Portico.</p> */}
//             <p style={styles.textWhite}>Portico takes the risk out of the buying process by making product research more convenient. Join the growing community of savvy shoppers who are using a new method of search to make better decisions.</p>
//             {/* <p style={styles.textWhite}>Get Portico and start actually saving time and money while you shop online.</p> */}
//           </div>
//       </div>

//       <div style={styles.sectionContainer2} >
//         <div style={styles.containerText2} >
//           <div style={styles.subsectionContainer}>
//             <div style={styles.svgContainer} >
//               <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/allInOne.svg" alt='pioneerYourBrand' style={styles.svgIcons} />
//             </div>
//             <h1 style={styles.textImpactWhite2}>DISOVER WHAT</h1>
//             <h1 style={styles.textImpactWhite2}>SHOPPING CAN BE</h1>
//             <p style={styles.textWhite}>Online shopping is broken and Portico is designed to fix it. Unlike traditional search engines, Portico exists to provide direction during your shopping journey.</p>
//             {/* <p style={styles.textWhite}>By using new advancements in AI to deliver answers and not ads, Portico has been able to build tools that allow for effective product research.</p> */}
//             <p style={styles.textWhite}>Learn about how Portico creates a new method of online shopping.</p>
//             <div style={styles.buttonParentContainer}>
//               <div style={styles.buttonParent}>
//                 <Link to="/searchandshop" style={{ textDecoration: 'none' }}>
//                   <button style={styles.containerButton}>
//                     <p style={styles.buttonTextWhite}>Learn More</p>
//                   </button>
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div style={styles.containerImage} >
//           <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/homeImage2.png" alt='ShopImage' style={styles.largeImages} />
//         </div>
//       </div>

//       <div style={styles.sectionContainer3} >
//         <div style={styles.containerImage} >
//           <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/PorticoConnect.png" alt='ConnectImage' style={styles.largeImages} />
//         </div>
//         <div style={styles.containerText2} >
//         <div style={styles.subsectionContainer}>
//           <div style={styles.svgContainer} >
//             <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/launch.svg" alt='pioneerYourBrand' style={styles.svgIcons} />
//           </div>
//           <h1 style={styles.textImpactWhite2}>FIND TIPS & TRICKS</h1>
//           <p style={styles.textWhite}>The internet is full of astroturfed conversations from anonymous strangers trying to mislead you.</p>
//           <p style={styles.textWhite}>Portico supplements the shopping experience with an engaged community that is dedicated to authentic reviews and candid recomendations.</p>
//           <div style={styles.buttonParentContainer}>
//             <div style={styles.buttonParent}>
//               <Link to="/discover" style={{ textDecoration: 'none' }}>
//                 <button style={styles.containerButton}>
//                   <p style={styles.buttonTextWhite}>Learn More</p>
//                 </button>
//               </Link>
//             </div>
//           </div>
//         </div>
//         </div>
//       </div>

//     </div>
//   );
// }

// export default HomePage;



//////////
//// ORIGINAL WEBSITE HOMEPAGE BEFORE 06/14/2024

// import React from 'react';
// import { Link } from 'react-router-dom';

// function HomePage() {
//   // Define a function to dynamically change styles based on screen width
//   const getResponsiveStyles = () => {
//     const isMobile = window.innerWidth <= 768; // Example breakpoint at 768px

//     const baseStyles = {
//       parentContainer: {
//         display: 'flex',
//         flexDirection: 'column',
//         width: '100%',
//         height: '100%',
//         backgroundColor: 'black',
//       },
//       sectionContainer1: {
//         display: 'flex',
//         flexDirection: isMobile ? 'column' : 'row',
//         width: '100%',
//         alignItems: 'center',
//         backgroundColor: '#1A1A1A', //F2F2F2 //01457B //002E4D
//         backgroundSize: 'cover', 
//         backgroundPosition: 'center',
//       },
//       sectionContainer2: {
//         display: 'flex',
//         flexDirection: isMobile ? 'column' : 'row',
//         width: '100%',
//         alignItems: 'center',
//         backgroundColor: '#1A1A1A', //backgroundColor: isMobile ? '#1A1A1A' : '#f2f2f2', //f2f2f2 //1A1A1A
//       },
//       sectionContainer3: {
//         display: 'flex',
//         flexDirection: isMobile ? 'column' : 'row',
//         width: '100%',
//         alignItems: 'center',
//         backgroundColor: '#1A1A1A', // backgroundColor: isMobile ? '#1A1A1A' : '#f2f2f2', //f2f2f2 //162A4A
//       },
//       subsectionContainer: {
//         flex: 1,
//         rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
//         display: 'flex',
//         flexDirection: 'column',
//         height: '100%',
//         width: isMobile ? '100%' : '75%', //width: '100%',
//         backgroundColor: '#1A1A1A',// backgroundColor: isMobile ? '#1A1A1A' : '#f2f2f2', //backgroundColor: '#f2f2f2',
//         // borderRadius: isMobile ? 5 : 10,
//         // padding: isMobile ? 10 : 15,
//         // margin: 20,
//       },
//       svgContainer: {
//         width: '100%',
//         display: 'flex',
//         alignContent: 'center',
//         alignItems: 'center',
//         justifyContent: 'center',
//       },
//       svgIcons: {
//         width: isMobile ? '10%' : '10%',
//         margin: 0,
//         padding: 0,
//         // backgroundColor: 'red'
//       },
//       largeImages: {
//         width: isMobile ? '100%' : '100%', 
//         height: 'auto',
//       },
//       largeImages2: {
//         width: isMobile ? '100%' : '100%',
//         height: 'auto',
//       },
//       buttonParentContainer: {
//         width: '100%',
//         display: 'flex',
//         alignContent: 'center',
//         alignItems: 'center',
//         justifyContent: 'center',
//         padding: '5px 0',
//       },
//       buttonParent: {
//         // width: '50%',
//       },
//       containerButton: {
//         backgroundColor: '#FF8C00',
//         borderRadius: 10,
//         borderWidth: 0,
//         padding: 10,
//       },

//       containerImage: {
//         flex: 1,
//         display: 'flex',
//         justifyContent: 'center',
//         order: isMobile ? -1 : 1, // Image before text in mobile view
//       },
//       containerText: {
//         flex: 1,
//         rowGap: isMobile ? '12px' : '25px',
//         display: 'flex',
//         flexDirection: 'column',
//         padding: isMobile ? '20px' : '40px',
//         fontFamily: 'Impact, Charcoal, sans-serif',
//         order: isMobile ? 0 : 1, // Text after image in desktop view
//       },
//       containerText2: {
//         flex: 1,
//         rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         alignContent: 'center',
//         height: '100%',
//         flexDirection: 'column',
//         padding: isMobile ? '20px' : '40px',
//         fontFamily: 'Impact, Charcoal, sans-serif',
//         order: isMobile ? 0 : 1, // Same logic applied to this text container as well
//       },

//       textImpactWhite1: {
//         color: 'white',
//         fontSize: isMobile ? 45 : 110, //fontSize: isMobile ? 30 : 110,
//         textAlign: 'center',
//         fontFamily: 'Impact, Charcoal, sans-serif', 
//         transform: 'skewX(-20deg)', 
//         margin: 0,
//       },
//       textImpactBlack1: {
//         color: '#1A1A1A',
//         fontSize: isMobile ? 30 : 110,
//         fontFamily: 'Impact, Charcoal, sans-serif', 
//         transform: 'skewX(-20deg)', 
//         margin: 0,
//       },
//       textImpactWhite2: {
//         color: 'white',
//         fontSize: isMobile ? 30 : 90, //fontSize: isMobile ? 30 : 45,
//         textAlign: 'center',
//         fontFamily: 'Impact, Charcoal, sans-serif',
//         transform: 'skewX(-20deg)', 
//         margin: 0,
//       },
//       textImpactBlack2: {
//         color: '#1a1a1a', // color: '#1a1a1a',// color: isMobile ? '#f2f2f2' : '#1a1a1a', // color: '#1a1a1a',
//         fontSize: isMobile ? 30 : 90, //fontSize: isMobile ? 30 : 45,
//         textAlign: 'center',
//         fontFamily: 'Impact, Charcoal, sans-serif',
//         transform: 'skewX(-20deg)', 
//         margin: 0,
//       },
//       textWhiteHeader: {
//         color: 'white',
//         fontFamily: 'sans-serif',
//         fontSize: isMobile ? 15 : 25,
//         fontWeight: '500',
//         margin: 0,
//         textAlign: 'center',
//       },
//       textWhite: {
//         color: 'white',
//         fontFamily: 'sans-serif',
//         fontSize: isMobile ? 15 : 25,
//         fontWeight: '500',
//         margin: 0,
//       },
//       textBlack: {
//         color: '#1a1a1a',  //color: isMobile ? '#f2f2f2' : '#1a1a1a',
//         fontFamily: 'sans-serif',
//         fontSize: isMobile ? 15 : 25,
//         fontWeight: '500',
//         margin: 0,
//       },
//       buttonTextWhite: {
//         color: 'white',
//         width: '100%',
//         fontFamily: 'sans-serif',
//         fontSize: 20,
        
//         fontWeight: '600',
//         margin: 0,
//       }
//     };

//     return baseStyles;
//   };

//   const styles = getResponsiveStyles();

//   return (
//     <div style={styles.parentContainer} >

//       <div style={styles.sectionContainer1} >
//           <div style={styles.containerImage} >
//             <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/PorticoWebsite.png" alt='topImage' style={styles.largeImages} />
//           </div>
//           <div style={styles.containerText} >
//             <h1 style={styles.textImpactWhite1}>PORTICO</h1>
//             {/* <p style={styles.textWhiteHeader}>Download Portico and join the ultimate online shopping experience.</p> */}
//             <p style={styles.textWhiteHeader}>Download Portico and join the community of shoppers actually saving time and money online.</p>
//             <div style={styles.buttonParentContainer}>
//               <div style={styles.buttonParent}>
//                 <a href="https://apps.apple.com/us/app/portico-shopping-portal/id6477725753" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
//                   {/* <img src={AppStoreBadgeBlack} alt="Download from the App Store" /> */}
//                   <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/appStoreBadgeBlack.svg" alt='topImage' style={styles.largeImages} />
//                 </a>
//               </div>
//             </div>
//             <p style={styles.textWhite}>Say goodbye to endless searching, price comparison hassles, and the fear of overpaying. Join the growing community of savvy shoppers who've transformed their online shopping and selling experience with Portico.</p>
//             {/* <p style={styles.textWhite}>Get Portico and start actually saving time and money while you shop online.</p> */}
//           </div>
//       </div>

//       <div style={styles.sectionContainer2} >
//         <div style={styles.containerText2} >
//           <div style={styles.subsectionContainer}>
//             <div style={styles.svgContainer} >
//               <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/allInOne.svg" alt='pioneerYourBrand' style={styles.svgIcons} />
//             </div>
//             <h1 style={styles.textImpactWhite2}>DISOVER WHAT</h1>
//             <h1 style={styles.textImpactWhite2}>SHOPPING CAN BE</h1>
//             <p style={styles.textWhite}>Join the world of savings with Portico's advanced product comparison technology.</p>
//             <p style={styles.textWhite}>We scour the internet to bring you the lowest price listings on any item, ensuring you never overpay again. Whether it's the latest tech gadget, your favorite fashion piece, or everyday essentials, Portico helps you get the best deal available.</p>
//             <p style={styles.textWhite}>Discover the New Potential of Online Shopping with Portico.</p>
//             <div style={styles.buttonParentContainer}>
//               <div style={styles.buttonParent}>
//                 <Link to="/shop" style={{ textDecoration: 'none' }}>
//                   <button style={styles.containerButton}>
//                     <p style={styles.buttonTextWhite}>Learn More</p>
//                   </button>
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div style={styles.containerImage} >
//           <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/PorticoShop.png" alt='ShopImage' style={styles.largeImages} />
//         </div>
//       </div>

//       <div style={styles.sectionContainer3} >
//         <div style={styles.containerImage} >
//           <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/PorticoConnect.png" alt='ConnectImage' style={styles.largeImages} />
//         </div>
//         <div style={styles.containerText2} >
//         <div style={styles.subsectionContainer}>
//           <div style={styles.svgContainer} >
//             <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/launch.svg" alt='pioneerYourBrand' style={styles.svgIcons} />
//           </div>
//           <h1 style={styles.textImpactWhite2}>BUILD YOUR BRAND</h1>
//           <p style={styles.textWhite}>In today's digital marketplace, standing out is more important than ever. At Portico, we offer a dynamic platform designed not just to showcase your products but to catapult your brand into the spotlight.</p>
//           <div style={styles.buttonParentContainer}>
//             <div style={styles.buttonParent}>
//               <Link to="/buildyourbrand" style={{ textDecoration: 'none' }}>
//                 <button style={styles.containerButton}>
//                   <p style={styles.buttonTextWhite}>Learn More</p>
//                 </button>
//               </Link>
//             </div>
//           </div>
//         </div>
//         </div>
//       </div>

//     </div>
//   );
// }

// export default HomePage;
