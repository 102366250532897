import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import HomeBackground1 from './assets/homeBackground1.png';

function SearchPage() {
  const getResponsiveStyles = () => {
    const isMobile = window.innerWidth <= 768;

    const baseStyles = {
      parentContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
      },
      sectionContainer1: {
        display: 'flex',
        // flexDirection: isMobile ? 'column' : 'row',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        backgroundColor: '#1a1a1a', //white //1a1a1a
        backgroundImage: `url(${HomeBackground1})`,
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
        paddingBottom: isMobile ? 300 : 0,
      },
      sectionContainer2: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        alignItems: 'center',
        backgroundColor: '#1A1A1A',
      },
      sectionContainer3: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        alignItems: 'center',
        backgroundColor: '#1A1A1A',
      },
      subsectionContainer: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: isMobile ? '100%' : '75%',
        backgroundColor: '#1A1A1A',
      },
      subsectionTitle: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: isMobile ? '100%' : '75%',
        backgroundColor: '#1A1A1A',
      },
      svgContainer: {
        width: '100%',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      },
      svgIcons: {
        width: isMobile ? '10%' : '10%',
        margin: 0,
        padding: 0,
      },
      largeImages: {
        width: '100%', 
        objectFit: 'cover',
        // marginBottom: 20,
      },
      largeImages2: {
        width: isMobile ? '100%' : '100%',
        height: 'auto',
      },
      buttonParentContainer: {
        width: '100%',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px 0',
      },
      motionImage1: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        backgroundColor: 'transparent', //white //1a1a1a
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
      },
      appStoreButtonParentContainer: {
        position: 'absolute',
        bottom: isMobile ? '50px' : '50px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: isMobile ? 'auto' : '100%',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 3,
        cursor: 'pointer',
      },
      buttonParent: {},
      containerButton: {
        backgroundColor: '#FF8C00',
        borderRadius: 50,
        borderWidth: 0,
        padding: "10px 50px 10px 50px",
        cursor: 'pointer',
      },

      containerImage: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        order: isMobile ? -1 : 1,
      },
      containerImageSection1: {
        // width: isMobile ? 'auto' : '40%',
        // height: isMobile ? 'auto' : '70vh',
        // position: 'static', 
        width: isMobile ? 'auto' : '45%', //width: isMobile ? 'auto' : '40%',
        height: isMobile ? '35vh' : '68vh', //height: isMobile ? 'auto' : '70vh',
        position: 'relative', //static
      },
//       containerImageSection1: {
//         width: isMobile ? 'auto' : '45%', //width: isMobile ? 'auto' : '40%',
//         height: isMobile ? '35vh' : '68vh', //height: isMobile ? 'auto' : '70vh',
//         position: 'relative', //static
//       },
      containerText: {
        flex: 1,
        rowGap: isMobile ? '12px' : '25px',
        display: 'flex',
        flexDirection: 'column',
        padding: isMobile ? '20px' : '40px',
        fontFamily: 'Impact, Charcoal, sans-serif',
        order: isMobile ? 0 : 1,
      },
      containerTextSection1: {
        flex: 1,
        rowGap: isMobile ? '12px' : '25px',
        display: 'flex',
        flexDirection: 'column',
        padding: isMobile ? '20px' : '40px',
        paddingTop: isMobile ? 70 : 70,
        fontFamily: 'Impact, Charcoal, sans-serif',
        zIndex: 2,
        // order: isMobile ? 0 : 1,
      },
      containerText2: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
        flexDirection: 'column',
        padding: isMobile ? '20px' : '40px',
        fontFamily: 'Impact, Charcoal, sans-serif',
        order: isMobile ? 0 : 1,
        // order: isMobile ? 1 : 0, //image first
      },

      textImpactWhite1: {
        color: 'white',
        fontSize: isMobile ? 45 : 110,
        textAlign: 'center',
        fontFamily: 'Impact, Charcoal, sans-serif', 
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      textImpactBlack1: {
        color: '#1A1A1A',
        fontSize: isMobile ? 45 : 110,
        textAlign: 'center',
        fontFamily: 'Impact, Charcoal, sans-serif', 
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      textImpactWhite2: {
        color: 'white',
        fontSize: isMobile ? 30 : 60,
        textAlign: 'center',
        fontFamily: 'Impact, Charcoal, sans-serif',
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      textImpactBlack2: {
        color: '#1a1a1a',
        fontSize: isMobile ? 30 : 90,
        textAlign: 'center',
        fontFamily: 'Impact, Charcoal, sans-serif',
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      textWhiteHeader: {
        color: 'white',
        fontFamily: 'sans-serif',
        fontSize: isMobile ? 15 : 25,
        fontWeight: '500',
        margin: 0,
        textAlign: 'center',
      },
      textWhiteHeader2: {
        color: 'white',
        fontFamily: 'sans-serif',
        fontSize: isMobile ? 15 : 25,
        fontWeight: '500',
        margin: 0,
        padding: 0,
        textAlign: 'center',
      },
      textBlackHeader: {
        color: 'black',
        fontFamily: 'sans-serif',
        fontSize: isMobile ? 15 : 25,
        fontWeight: '500',
        margin: 0,
        textAlign: 'center',
      },
      textWhiteLarge: {
        color: 'white',
        fontFamily: 'sans-serif',
        // fontSize: isMobile ? 25 : 40,
        // fontSize: isMobile ? 20 : 35,
        fontSize: isMobile ? 15 : 30,
        fontWeight: '800',
        margin: 0,
      },
      textWhite: {
        color: 'white',
        fontFamily: 'sans-serif',
        // fontSize: isMobile ? 15 : 25,
        fontSize: isMobile ? 15 : 20,
        fontWeight: '100',
        margin: 0,
      },
      textBlack: {
        color: 'black',
        fontFamily: 'sans-serif',
        fontSize: isMobile ? 15 : 25,
        fontWeight: '500',
        margin: 0,
      },
      buttonTextWhite: {
        color: 'white',
        width: '100%',
        fontFamily: 'sans-serif',
        fontSize: isMobile ? 15 : 20,
        fontWeight: '600',
        margin: 0,
      },

      sectionContainer20: {
        // paddingTop: isMobile ? '100px' : '150px', 
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        alignItems: 'center',
        backgroundColor: '#1a1a1a', //f2f2f2 //1A1A1A
      },
      containerText20: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
        flexDirection: 'column',
        padding: isMobile ? '20px' : '40px',
        fontFamily: 'Impact, Charcoal, sans-serif',
        // order: isMobile ? 0 : 1, // Same logic applied to this text container as well
      },
      subsectionContainer20: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        // width: '100%',
        width: isMobile ? '80%' : '100%',
      },
      sectionContainer201: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        // width: '100%',
        width: isMobile ? '80%' : '100%',
        backgroundColor: '#393838'
      },
      textImpactWhite20: {
        color: 'white',
        // fontSize: isMobile ? 30 : 90, //fontSize: isMobile ? 30 : 45,
        fontSize: isMobile ? 30 : 60, //fontSize: isMobile ? 30 : 45,
        textAlign: 'left', //center
        fontFamily: 'Impact, Charcoal, sans-serif',
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      textImpactWhite20C: {
        color: 'white',
        // fontSize: isMobile ? 30 : 90, //fontSize: isMobile ? 30 : 45,
        fontSize: isMobile ? 30 : 60, //fontSize: isMobile ? 30 : 45,
        textAlign: 'center', //center
        fontFamily: 'Impact, Charcoal, sans-serif',
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      sectionContainer30: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        alignItems: 'center',
        backgroundColor: '#1A1A1A', //1A1A1A //f2f2f2 // //162A4A
      },
      containerImage30: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
      },
      containerText30: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
        flexDirection: 'column',
        padding: isMobile ? '20px' : '40px',
        fontFamily: 'Impact, Charcoal, sans-serif',
      },
      subsectionContainer0: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: isMobile ? '100%' : '75%',
        // backgroundColor: 'white', //1a1a1a //f2f2f2
        borderRadius: isMobile ? 5 : 10,
        padding: isMobile ? 10 : 15, //padding: isMobile ? 10 : 50,
      },
    };

    return baseStyles;
  };

  const styles = getResponsiveStyles();

  // const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });
  const [ref4, inView4] = useInView({ triggerOnce: true });

  const imageVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } }
  };

  return (


    <div style={styles.parentContainer} >
      <div style={styles.sectionContainer1} >
        <div style={styles.containerTextSection1} >
          <h1 style={styles.textImpactWhite1}>BETTER ONLINE SHOPPING</h1>
          <div>
            <p style={styles.textWhiteHeader2}>Shop with Direction.</p>
          </div>
        </div>

        {/* <p style={styles.textWhite}>Portico takes the risk out of the buying process by making product research more convenient. Join the growing community of savvy shoppers who are using a new method of search to make better decisions.</p> */}
        <motion.div style={styles.motionImage1} initial="hidden" animate="visible" variants={imageVariants}>
          <div style={styles.containerImageSection1} >
            <motion.img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/searchImage1.png" alt='topImage' style={styles.largeImages} />
          </div>
        </motion.div>
      </div>

        {/* <div style={styles.sectionContainer20} >
          <div style={styles.containerText20} >
            <div style={styles.subsectionContainer20}>
              <h1 style={styles.textImpactWhite20C}>SHOP EVERYTHING, EVERYWHERE, ALL IN ONE</h1>
            </div>
          </div>
        </div> 
        <div style={styles.sectionContainer30} >
          <div style={styles.containerImage30} >
            <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/promo5Image.png" alt='ShopImage2' style={styles.largeImages} />
          </div>
        </div> */}

        {/* <div style={styles.sectionContainer30} >
          <div style={styles.containerImage30} >
            <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/searchImage2.png" alt='ShopImage2' style={styles.largeImages} />
          </div>
          <div style={styles.containerText30} >
            <div style={styles.subsectionContainer0}>
              <h1 style={styles.textImpactWhite20}>EVERYTHING,</h1>
              <h1 style={styles.textImpactWhite20}>EVERYWHERE, ALL IN ONE</h1>
              
              <p style={styles.textWhiteLarge}>Search by Store</p>
              <p style={styles.textWhite}>Don't shop using 20 tabs and don't bookmark dozens of websites. Make your product research and acquistion more convenient.</p>
              <p style={styles.textWhiteLarge}>Sort by Price</p>
              <p style={styles.textWhite}>Traditional search engines aren't built to help you shop, they are designed to deliver you advertising. When you search with Portico, you get answers not ads.</p>
              <p style={styles.textWhiteLarge}>Discover New Products</p>
              <p style={styles.textWhite}>Portico detects features of a product and finds you high-quality knock-offs and budget-friendly alternatives.</p> 
            </div>
          </div>
        </div> */}
 
      <motion.div ref={ref2} style={styles.sectionContainer2} initial="hidden" animate={inView2 ? "visible" : "hidden"} variants={imageVariants}>
        <div style={styles.containerText2} >
          <div style={styles.subsectionContainer}>
            {/* <p style={styles.textWhiteLarge}>Find What's New</p> */}
            <p style={styles.textImpactWhite2}>Find What's New</p>
            <p style={styles.textWhite}>It's never been easier to buy online, but investing in quality has never been more challenging.</p>
            <p style={styles.textWhite}>When shopping for the latest style, Portico makes it easier to spend your money on decent products.</p>
          </div>
        </div>
        <div style={styles.containerImage} >
          {/* <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/homeImage2.png" alt='ShopImage' style={styles.largeImages} /> */}
          <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/findSimilarImage.png" alt='ShopImage' style={styles.largeImages} />
          {/* <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/findSimilarImage.png" alt='ShopImage' style={styles.largeImages} /> */}
        </div>
      </motion.div>

      <motion.div ref={ref3} style={styles.sectionContainer3} initial="hidden" animate={inView3 ? "visible" : "hidden"} variants={imageVariants}>
        <div style={styles.containerImage} >
          <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/homeImage2.png" alt='ConnectImage' style={styles.largeImages} />
        </div>
        <div style={styles.containerText2} >
          <div style={styles.subsectionContainer}>
          {/* <p style={styles.textWhiteLarge}>Find What's Near</p> */}
          <p style={styles.textImpactWhite2}>Find What's Near</p>
            <p style={styles.textWhite}>Ordering online isn't always the cheapest or the most convenient way to shop.</p>
            <p style={styles.textWhite}>When time is of the essense, Portico's predictive product system lets you see which stores carry what you need before you leave your house. When you search Portico, you can get what you need faster than buying it online without running all over town.</p>
          </div>
        </div>
      </motion.div>

      <motion.div ref={ref4} style={styles.sectionContainer3} initial="hidden" animate={inView4 ? "visible" : "hidden"} variants={imageVariants}>

        <div style={styles.containerText2} >
          <div style={styles.subsectionContainer}>
            {/* <p style={styles.textWhiteLarge}>Find What's Next</p> */}
            <p style={styles.textImpactWhite2}>Find What's Next</p>
            <p style={styles.textWhite}>Looking to keep up with the latest trends? Smart Search automatically finds you the same or similar product for a different price at a different store by searching across the web using the image and description.</p>
            <p style={styles.textWhite}>Let Portico help you shop for used versions so that you can be trendy and sustainable.</p>
          </div>

          <div style={styles.buttonParentContainer}>
            <div style={styles.buttonParent}>
              <Link to="/discover" style={{ textDecoration: 'none' }}>
                <button style={styles.containerButton}>
                  <p style={styles.buttonTextWhite}>Learn More</p>
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div style={styles.containerImage} >
          <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/findWhatsNext.png" alt='ConnectImage' style={styles.largeImages} />
        </div>
        
      </motion.div>
    </div>
  );
}

export default SearchPage;











// import React from 'react';
// import { motion } from 'framer-motion';
// import { useInView } from 'react-intersection-observer';
// import HomeBackground1 from './assets/homeBackground1.png';

// function SearchPage() {
//   const getResponsiveStyles = () => {
//     const isMobile = window.innerWidth <= 768; 

//     const baseStyles = {
//       parentContainer: {
//         display: 'flex',
//         flexDirection: 'column',
//         width: '100%',
//         height: '100%',
//         backgroundColor: 'black',
//       },
//       sectionContainer1: {
//         display: 'flex',
//         paddingTop: '70px',
//         flexDirection: 'column',
//         width: '100%',
//         alignItems: 'center',
//         backgroundImage: `url(${HomeBackground1})`,
//         backgroundSize: 'cover', 
//         backgroundPosition: 'center',
//       },
//       sectionContainer2: {
//         paddingTop: isMobile ? '100px' : '150px', 
//         display: 'flex',
//         flexDirection: isMobile ? 'column' : 'row',
//         width: '100%',
//         alignItems: 'center',
//         backgroundColor: '#1A1A1A',// backgroundColor: isMobile ? '#1A1A1A' : '#f2f2f2', //f2f2f2 //1A1A1A
//       },
      
//       sectionContainer3: {
//         display: 'flex',
//         flexDirection: isMobile ? 'column' : 'row',
//         width: '100%',
//         alignItems: 'center',
//         backgroundColor: '#1A1A1A', //162A4A
//       },

//       sectionContainer4: {
//         display: 'flex',
//         // paddingTop: isMobile ? '100px' : '80px',
//         flexDirection: isMobile ? 'column' : 'row',
//         width: '100%',
//         alignItems: 'center',
//         backgroundColor: '#1a1a1a', //1a1a1a //f2f2f2 //1A1A1A
//       },
//       sectionContainer5: {
//         display: 'flex',
//         flexDirection: 'column',
//         width: '100%',
//         alignItems: 'center',
//         backgroundColor: '#1a1a1a', //1a1a1a //f2f2f2 //1A1A1A
//       },
//       subsectionContainer: {
//         flex: 1,
//         rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
//         display: 'flex',
//         flexDirection: 'column',
//         height: '100%',
//         // width: '100%',
//         width: isMobile ? '100%' : '75%',
//         backgroundColor: '#1a1a1a', //f2f2f2
//         borderRadius: isMobile ? 5 : 10,
//         padding: isMobile ? 10 : 15,
//         // margin: 20,
//       },
//       subsectionContainer2: {
//         flex: 1,
//         rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
//         display: 'flex',
//         flexDirection: 'column',
//         height: '100%',
//         // backgroundColor: 'red',
//         width: '100%',
//         width: isMobile ? '80%' : '100%',
//         // backgroundColor: '#1a1a1a', //f2f2f2
//         // borderRadius: isMobile ? 5 : 10,
//         // padding: isMobile ? 10 : 15,
//         // margin: 20,
//       },
//       containerImage: {
//         flex: 1,
//         display: 'flex',
//         justifyContent: 'center',
//         order: isMobile ? -1 : 1, // Image before text in mobile view
//       },
//       containerImage1: {
//         flex: 1,
//         display: 'flex',
//         justifyContent: 'center',
//         // order: isMobile ? 0 : 1, // Image before text in mobile view
//       },
//       containerImage2: {
//         flex: 1,
//         display: 'flex',
//         justifyContent: 'center',
//         // order: isMobile ? -1 : 1, // Image before text in mobile view
//       },
//       containerImage3: {
//         flex: 1,
//         display: 'flex',
//         justifyContent: 'center',
//         // order: isMobile ? -1 : 1, // Image before text in mobile view
//       },
//       containerImage4: {
//         flex: 1,
//         display: 'flex',
//         justifyContent: 'center',
//         backgroundColor: '#ff8c00', //f2f2f2 //ff8c00
//         padding: '20px 10px',
//         order: isMobile ? -1 : 1,
//       },
//       containerImage5: {
//         flex: 1,
//         display: 'flex',
//         justifyContent: 'center',
//         backgroundColor: '#ff8c00', //f2f2f2 //ff8c00
//         padding: '20px 10px',
//         order: isMobile ? 0 : 1,
//       },
//       containerImage6: {
//         flex: 1,
//         display: 'flex',
//         justifyContent: 'center',
//         width: isMobile ? 'auto' : '55%',
//       },
//       containerImageSection1: {
//         width: isMobile ? 'auto' : '45%', //width: isMobile ? 'auto' : '40%',
//         height: isMobile ? '35vh' : '68vh', //height: isMobile ? 'auto' : '70vh',
//         position: 'relative', //static
//       },
//       // containerImageSection5: {
//       //   width: isMobile ? 'auto' : '65%', //width: isMobile ? 'auto' : '40%',
//       //   height: isMobile ? '35vh' : '68vh', //height: isMobile ? 'auto' : '70vh',
//       //   position: 'relative', //static
//       //   // backgroundColor: 'red',
//       // },
//       containerText: {
//         flex: 1,
//         rowGap: isMobile ? '12px' : '25px',
//         display: 'flex',
//         flexDirection: 'column',
//         padding: isMobile ? '20px' : '40px',
//         fontFamily: 'Impact, Charcoal, sans-serif',
//         order: isMobile ? 0 : 1, // Text after image in desktop view
//       },
//       containerText1: {
//         flex: 1,
//         rowGap: isMobile ? '12px' : '25px',
//         display: 'flex',
//         flexDirection: 'column',
//         padding: isMobile ? '20px' : '40px',
//         fontFamily: 'Impact, Charcoal, sans-serif',
//         // order: isMobile ? 0 : 1, // Text after image in desktop view
//       },
//       containerText2: {
//         flex: 1,
//         rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         alignContent: 'center',
//         height: '100%',
//         flexDirection: 'column',
//         padding: isMobile ? '20px' : '40px',
//         fontFamily: 'Impact, Charcoal, sans-serif',
//         // order: isMobile ? 0 : 1, // Same logic applied to this text container as well
//       },
//       containerText3: {
//         flex: 1,
//         rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         alignContent: 'center',
//         height: '100%',
//         flexDirection: 'column',
//         padding: isMobile ? '20px' : '40px',
//         fontFamily: 'Impact, Charcoal, sans-serif',
//         // order: isMobile ? 0 : 1, // Same logic applied to this text container as well
//       },
//       containerText4: {
//         flex: 1,
//         rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         alignContent: 'center',
//         height: '100%',
//         flexDirection: 'column',
//         padding: isMobile ? '20px' : '40px',
//         fontFamily: 'Impact, Charcoal, sans-serif',
//         // order: isMobile ? 0 : 1, 
//       },
//       containerText5: {
//         flex: 1,
//         rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         alignContent: 'center',
//         height: '100%',
//         flexDirection: 'column',
//         padding: isMobile ? '20px' : '40px',
//         fontFamily: 'Impact, Charcoal, sans-serif',
//         // order: isMobile ? 0 : 1, 
//       },
//       // containerText4: {
//       //   flex: 1,
//       //   rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
//       //   display: 'flex',
//       //   alignItems: 'center',
//       //   justifyContent: 'center',
//       //   alignContent: 'center',
//       //   height: '100%',
//       //   flexDirection: 'column',
//       //   padding: isMobile ? '20px' : '40px',
//       //   fontFamily: 'Impact, Charcoal, sans-serif',
//       //   order: isMobile ? 0 : 1, // Same logic applied to this text container as well
//       // },
//       // subsectionContainer4: {
//       //   flex: 1,
//       //   rowGap: isMobile ? '10px' : '15px',
//       //   display: 'flex',
//       //   flexDirection: 'column',
//       //   height: '100%',
//       //   width: isMobile ? '100%' : '80%', //80 //width: '100%', //80
//       //   // margin: 20,
//       // },
//       // subContainerText4: {
//       //   flex: 1,
//       //   // rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
//       //   display: 'flex',
//       //   alignItems: 'center',
//       //   justifyContent: 'center',
//       //   alignContent: 'center',
//       //   height: '100%',
//       //   flexDirection: 'column',
//       //   padding: isMobile ? '20px' : '40px',
//       //   fontFamily: 'Impact, Charcoal, sans-serif',
//       //   order: isMobile ? 0 : 1, // Same logic applied to this text container as well
//       // },
//       largeImages: {
//         width: isMobile ? '100%' : '100%', 
//         height: 'auto',
//       },
//       largeImages2: {
//         width: isMobile ? '100%' : '100%',
//         height: 'auto',
//       },
//       buttonParent: {
//         width: '50%',
//       },
//       containerButton: {
//         backgroundColor: '#FF8C00',
//         borderRadius: 15,
//         borderWidth: 0,
//         padding: 10,
//       },

//       textImpactWhite1: {
//         color: 'white',
//         fontSize: isMobile ? 30 : 90, 
//         textAlign: 'center',
//         fontFamily: 'Impact, Charcoal, sans-serif', 
//         transform: 'skewX(-20deg)', 
//         margin: 0,
//         overflow: 'hidden',
//       },
//       textImpactBlack1: {
//         color: '#1A1A1A',
//         fontSize: isMobile ? 30 : 110,
//         fontFamily: 'Impact, Charcoal, sans-serif', 
//         transform: 'skewX(-20deg)', 
//         margin: 0,
//       },
//       textImpactWhite2: {
//         color: 'white',
//         fontSize: isMobile ? 30 : 90, //fontSize: isMobile ? 30 : 45,
//         textAlign: 'center',
//         fontFamily: 'Impact, Charcoal, sans-serif',
//         transform: 'skewX(-20deg)', 
//         margin: 0,
//       },
//       textImpactBlack2: {
//         color: '#1a1a1a',
//         fontSize: isMobile ? 30 : 90, //fontSize: isMobile ? 30 : 45,
//         textAlign: 'center',
//         fontFamily: 'Impact, Charcoal, sans-serif',
//         transform: 'skewX(-20deg)', 
//         margin: 0,
//       },
//       textWhite: {
//         color: 'white',
//         fontFamily: 'sans-serif',
//         fontSize: isMobile ? 15 : 25,
//         fontWeight: '500',
//         margin: 0,
//       },
//       textWhiteCarrot: {
//         color: 'white',
//         fontFamily: 'sans-serif',
//         fontSize: isMobile ? 30 : 90,
//         textAlign: 'center',
//         fontWeight: '500',
//         margin: 0,
//       },
//       textBlack: {
//         color: 'white', //1A1A1A
//         fontFamily: 'sans-serif',
//         fontSize: isMobile ? 15 : 25,
//         fontWeight: '500',
//         margin: 0,
//       },
//       buttonTextWhite: {
//         color: 'white',
//         fontFamily: 'sans-serif',
//         fontSize: 20,
        
//         fontWeight: '600',
//         margin: 0,
//       },
//       svgContainer: {
//         width: '100%',
//         display: 'flex',
//         alignContent: 'center',
//         alignItems: 'center',
//         justifyContent: 'center',
//       },
//       svgIcons: {
//         width: isMobile ? '10%' : '10%',
//         margin: 0,
//         padding: 0,
//         // backgroundColor: 'red'
//       },
//     };

//     return baseStyles;
//   };

//   // Call the function to get the current styles
//   const styles = getResponsiveStyles();

//   return (
//     <div style={styles.parentContainer} >

//       <div style={styles.sectionContainer1} >
//           <div style={styles.containerText1} >
//             <h1 style={styles.textImpactWhite1}>BETTER ONLINE SHOPPING</h1>
//             <p style={styles.textWhite}>While search engines are designed to keep you scrolling, Portico was designed to make product research more convenient.</p>
//             {/* <p style={styles.textWhite}>Portico was designed to make product research more convenient.</p> */}
//             {/* <h1 style={styles.textWhiteCarrot}>⌄</h1> */}
//             {/* <p style={styles.textWhite}>Ever wonder why shopping online is so time-consuming and difficult? It's really simple: traditional search engines are designed to keep you scrolling.</p>
//             <p style={styles.textWhite}>Portico's Premium search does not contain any advertising. When you shop using Portico, you can trust the results you see.</p>
//             <p style={styles.textWhite}>Find out how Portico makes satisfaction more common and more convenient.</p> */}
            
//           </div>
//           <div style={styles.containerImageSection1} >
//             <div style={styles.containerImage1} >
//               <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/searchImage1.png" alt='ShopImage1' style={styles.largeImages} />
//             </div>
//           </div>
//       </div>

//       {/* <div style={styles.sectionContainer4} >
//         <div style={styles.containerText4} >
//           <div style={styles.subsectionContainer4}>
//             <div style={styles.svgContainer} >
//               <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/pioneer.svg" alt='pioneerYourBrand' style={styles.svgIcons} />
//             </div>
//             <p style={styles.textImpactWhite2}>SEARCH DIRECTION</p>
//             <p style={styles.textBlack}>Seize the opportunity to be among the first to establish your brand on Portico, the cutting-edge destination for online shopping. This isn't just about being present; it's about leading the way and setting the standard for what your business can sell online.</p>
//             <div style={styles.svgContainer} >
//               <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/tailor.svg" alt='pioneerYourBrand' style={styles.svgIcons} />
//             </div>
//             <p style={styles.textImpactWhite2}>EFFECTIVE FILTERS</p>
//             <p style={styles.textBlack}>Break free from the constraints of conventional platforms. With Portico, you have the creative freedom to design your page in a way that truly reflects your brand’s ethos and aesthetic. Here, your business doesn’t have to conform — it stands out.</p>
//           </div>
//         </div>

//         <div style={styles.subContainerText4} >
//           <div style={styles.subsectionContainer4}>
//             <div style={styles.svgContainer} >
//               <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/growth.svg" alt='pioneerYourBrand' style={styles.svgIcons} />
//             </div>
//             <p style={styles.textImpactWhite2}>USE AI</p>
//             <p style={styles.textBlack}>Forget about crafting content to fit the latest viral trends. On Portico, your products are your voice. Build brand recognition and loyalty by showcasing the quality and uniqueness of what you offer, not through fleeting engagement tactics.</p>
//             <div style={styles.svgContainer} >
//               <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/focus.svg" alt='pioneerYourBrand' style={styles.svgIcons} />
//             </div>
//             <p style={styles.textImpactWhite2}>PRODUCT FOCUSED CONTENT</p>
//             <p style={styles.textBlack}>Simplify the discovery process for your audience. By uploading your products and featuring them in posts, you make it easier for shoppers to find and fall in love with what you have to offer. Portico bridges the gap between product listing and storytelling to ensure that your products are not just seen by consumers, but remembered, saved, and added to their lists.</p>
//           </div>
//         </div>
//       </div> */}

//       <div style={styles.sectionContainer2} >
//         <div style={styles.containerText2} >
//           <div style={styles.subsectionContainer2}>
//             <h1 style={styles.textImpactWhite2}>ALL YOUR FAVORITE STORES IN ONE</h1>
//           </div>
//         </div>
//       </div>

//       <div style={styles.sectionContainer3} >
//         <div style={styles.containerImage3} >
//           <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/searchImage2.png" alt='ShopImage2' style={styles.largeImages} />
//         </div>
//         <div style={styles.containerText3} >
//           <div style={styles.subsectionContainer}>
//             {/* <h1 style={styles.textImpactWhite2}>ALL YOUR FAVORITE</h1>
//             <h1 style={styles.textImpactWhite2}>STORES IN ONE</h1> */}
//             <p style={styles.textWhite}>Forget tab-switching and bookmarking dozens of websites. Portico brings a seamless shopping experience by aggregating products from several stores all at once.</p>
//             <p style={styles.textWhite}>Our intuitive platform allows you to browse, compare, and purchase from different retailers without leaving the comfort of your home.</p>
//             <p style={styles.textWhite}>Reverse image search not finding the no-name brands at other stores? Portico uses an advancing AI to detect features of a product to find you high-quality knock-offs and budget-friendly alternatives.</p>
//             <p style={styles.textWhite}>Find the cheapest prices on replicas that rival the originals in quality and style. It's a smart way to enjoy luxury without the hefty price tag.</p>
//           </div>
//         </div>
//       </div>

//       <div style={styles.sectionContainer4} >
//         <div style={styles.containerImage4} >
//           <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/searchImage3.png" alt='ShopImage3' style={styles.largeImages} />
//         </div>
//         <div style={styles.containerText4} >
//           <div style={styles.subsectionContainer}>
//             <h1 style={styles.textImpactWhite2}>AI POWERED RESEARCH</h1>
//             <p style={styles.textWhite}>Forget tab-switching and bookmarking dozens of websites. Portico brings a seamless shopping experience by aggregating products from several stores all at once.</p>
//             <p style={styles.textWhite}>Our intuitive platform allows you to browse, compare, and purchase from different retailers without leaving the comfort of your home.</p>
//             <p style={styles.textWhite}>Reverse image search not finding the no-name brands at other stores? Portico uses an advancing AI to detect features of a product to find you high-quality knock-offs and budget-friendly alternatives.</p>
//             <p style={styles.textWhite}>Find the cheapest prices on replicas that rival the originals in quality and style. It's a smart way to enjoy luxury without the hefty price tag.</p>
//           </div>
//         </div>
//       </div>

//       <div style={styles.sectionContainer4} >
//         <div style={styles.containerImage5} >
//           <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/searchImage6.png" alt='ShopImage3' style={styles.largeImages} />
//         </div>
//         <div style={styles.containerText4} >
//           <div style={styles.subsectionContainer}>
//             <h1 style={styles.textImpactWhite2}>STAY ORGANIZED WITH SMART SHOPPING LISTS</h1>
//             <p style={styles.textWhite}>Forget tab-switching and bookmarking dozens of websites. Portico brings a seamless shopping experience by aggregating products from several stores all at once.</p>
//             <p style={styles.textWhite}>Our intuitive platform allows you to browse, compare, and purchase from different retailers without leaving the comfort of your home.</p>
//             <p style={styles.textWhite}>Reverse image search not finding the no-name brands at other stores? Portico uses an advancing AI to detect features of a product to find you high-quality knock-offs and budget-friendly alternatives.</p>
//             <p style={styles.textWhite}>Find the cheapest prices on replicas that rival the originals in quality and style. It's a smart way to enjoy luxury without the hefty price tag.</p>
//           </div>
//         </div>
//       </div>

//       <div style={styles.sectionContainer5} >
//         <div style={styles.containerText5} >
//           <div style={styles.subsectionContainer}>
//             <h1 style={styles.textImpactWhite2}>STAY ORGANIZED WITH SMART SHOPPING LISTS</h1>
//           </div>
//         </div>
//         <div style={styles.containerImage5} >
//           <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/listQuadImage.png" alt='ShopImage4' style={styles.largeImages} />
//         </div>
//         <div style={styles.containerText5} >
//           <div style={styles.subsectionContainer}>
//             {/* <h1 style={styles.textImpactWhite2}>STAY ORGANIZED WITH SMART SHOPPING LISTS</h1> */}
//             <p style={styles.textWhite}>Forget tab-switching and bookmarking dozens of websites. Portico brings a seamless shopping experience by aggregating products from several stores all at once.</p>
//             <p style={styles.textWhite}>Our intuitive platform allows you to browse, compare, and purchase from different retailers without leaving the comfort of your home.</p>
//             <p style={styles.textWhite}>Reverse image search not finding the no-name brands at other stores? Portico uses an advancing AI to detect features of a product to find you high-quality knock-offs and budget-friendly alternatives.</p>
//             <p style={styles.textWhite}>Find the cheapest prices on replicas that rival the originals in quality and style. It's a smart way to enjoy luxury without the hefty price tag.</p>
//           </div>
//         </div>
//       </div>

//     </div>
//   );
// }

// export default SearchPage;