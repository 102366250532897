import React from 'react';

function TermsPage() {


    return (
        <div style={styles.containerParent}>
            <div style={styles.indentedSection1}>
                <h1 style={styles.text40White}>Portico Terms of Use and User Agreements</h1>

                <h1 style={styles.text20White}>Terms of Use (EULA)</h1>
                <div style={styles.indentedSection3}>
                    
                    <p style={styles.text20White}>Terms of Service</p>
                    <div style={styles.indentedSection3}>
                        <p style={styles.text20White}>One Portico, LLC builds technologies and services that enable people to connect with each other, build communities, and grow businesses. These Terms govern your use of our products, features, apps, services, technologies, and software we offer (the One Portico, LLC Products or Products), except where we expressly state that separate terms (and not these) apply. These Products are provided to you by One Portico, LLC.</p>
                        <p style={styles.text20White}>We don’t charge you to use the products and services covered by these Terms, unless we state otherwise. Instead, businesses and organizations, and other persons pay us to show you ads for their products and services. By using our Products, you agree that we can show you ads that we think may be relevant to you and your interests. We use your personal data to help determine which personalized ads to show you.</p>
                        <p style={styles.text20White}>We don’t sell your personal data to advertisers, and we don’t share information that directly identifies you (such as your name, email address, or other contact information) with advertisers unless you give us specific permission. Instead, advertisers can tell us things like the kind of audience they want to see their ads, and we show those ads to people who may be interested. We provide advertisers with reports about the performance of their ads that help them understand how people are interacting with their content. See the section below to learn more about how personalized advertising under these terms works on the One Portico, LLC Products.</p>
                        <p style={styles.text20White}>Our Privacy Policy explains how we collect and use your personal data to determine some of the ads you see and provide all of the other services described below. You can also go to your settings pages of the relevant One Portico, LLC Product at any time to review the privacy choices you have about how we use your data.</p>
                    </div>

                    <p style={styles.text20White}>1. The services we provide</p>
                    <div style={styles.indentedSection3}>
                        <p style={styles.text20White}>Our mission is to give people the power to build community and bring the world closer together. To help advance this mission, we provide the Products and services described below to you:</p>
                        <p style={styles.text20White}>Provide a personalized experience for you:</p>
                        <div style={styles.indentedSection3}>
                            <p style={styles.text20White}>Your experience on our platform is unlike anyone else's: from the posts, stories, events, ads, and other content you see in our News Feed or our video platform to the Pages you follow and other features you might use, such as Marketplace, and search. For example, we use data about the connections you make, the choices and settings you select, and what you share and do on and off our Products - to personalize your experience.</p>
                        </div>

                        <p style={styles.text20White}>Connect you with people and organizations you care about:</p>
                        <div style={styles.indentedSection3}>
                            <p style={styles.text20White}>We help you find and connect with people, groups, businesses, organizations, and others that matter to you across the One Portico, LLC Products you use. We use data to make suggestions for you and others - for example, groups to join, events to attend, Pages to follow or send a message to, shows to watch, and people you may want to become friends with. Stronger ties make for better communities, and we believe our services are most useful when people are connected to people, groups, and organizations they care about.</p> 
                            
                        </div>

                        <p style={styles.text20White}>Empower you to express yourself and communicate about what matters to you:</p>
                        <div style={styles.indentedSection3}>
                            <p style={styles.text20White}>There are many ways to express yourself on our platform to communicate with friends, family, and others about what matters to you - for example, sharing status updates, photos, videos, and stories across the One Portico, LLC Products (consistent with your settings), sending messages or making voice or video calls to a friend or several people, creating events or groups, or adding content to your profile. We have also developed, and continue to explore, new ways for people to use technology, such as augmented reality and 360 video to create and share more expressive and engaging content.</p>
                        </div>

                        <p style={styles.text20White}>Help you discover content, products, and services that may interest you:</p>
                        <div style={styles.indentedSection3}>
                            <p style={styles.text20White}>We show you personalized ads, offers, and other sponsored or commercial content to help you discover content, products, and services that are offered by the many businesses and organizations that use our platform and other One Portico, LLC Products. The next section explains this in more detail.</p>
                        </div>

                        <p style={styles.text20White}>Promote the safety, security, and integrity of our services, combat harmful conduct and keep our community of users safe:</p>
                        <div style={styles.indentedSection3}>
                            <p style={styles.text20White}>People will only build community on One Portico, LLC Products if they feel safe and secure. We work hard to maintain the security of our Products and services. We employ dedicated teams around the world and develop advanced technical systems to detect potential misuse of our Products, harmful conduct towards others, and situations where we may be able to help support or protect our community. If we learn of content or conduct like this, we take appropriate action. We share data across One Portico, LLC when we detect misuse or harmful conduct by someone using one of our Products or to help keep our Products, users, and the community safe. For example, we share information with One Portico, LLC that provides financial products and services to help them promote safety, security, and integrity and comply with applicable law. One Portico, LLC may access, preserve, use, and share any information it collects about you where it has a good faith belief it is required or permitted by law to do so. For more information, please review our Privacy Policy.</p>
                            <p style={styles.text20White}>In some cases, the Oversight Board may review our decisions, subject to its terms and bylaws.</p>
                        </div>

                        <p style={styles.text20White}>Use and develop advanced technologies to provide safe and functional services for everyone:</p>
                        <div style={styles.indentedSection3}>
                            <p style={styles.text20White}>We use and develop advanced technologies - such as artificial intelligence, machine learning systems, and augmented reality - so that people can use our Products safely regardless of physical ability or geographic location. For example, technology like this helps people who have visual impairments understand what or who is in photos or videos shared on our platform. We also build sophisticated network and communication technology to help more people connect to the internet in areas with limited access. And we develop automated systems to improve our ability to detect and remove abusive and dangerous activity that may harm our community and the integrity of our Products.</p>
                        </div>

                        <p style={styles.text20White}>Research ways to make our services better:</p>
                        <div style={styles.indentedSection3}>
                            <p style={styles.text20White}>We engage in research to develop, test, and improve our Products. This includes analyzing data we have about our users and understanding how people use our Products, for example by conducting surveys and testing and troubleshooting new features. Our Privacy Policy explains how we use data to support this research for the purposes of developing and improving our services.</p> 
                        </div>

                        <p style={styles.text20White}>Provide consistent and seamless experiences across the One Portico, LLC Company Products:</p>
                        <div style={styles.indentedSection3}>
                            <p style={styles.text20White}>Our Products help you find and connect with people, groups, businesses, organizations, and others that are important to you. We design our systems so that your experience is consistent and seamless across the different One Portico, LLC Company Products that you use. For example, we use data about the people you engage with on our platform to make it easier for you to connect with them on other Products.</p> 
                        </div>

                        <p style={styles.text20White}>Ensuring access to our services:</p>
                        <div style={styles.indentedSection3}>
                            <p style={styles.text20White}>To operate our global services and enable you to connect with people around the world, we need to transfer, store, and distribute content and data to our data centers, partners, service providers, vendors, and systems around the world, including outside your country of residence. The use of this global infrastructure is necessary and essential to provide our services. This infrastructure may be owned, operated, or controlled by One Portico, LLC, or its affiliates.</p> 
                        </div>
                    </div>

                    <p style={styles.text20White}>2. Your commitments to One Portico, LLC and our community</p>
                    <p style={styles.text20White}>We provide these services to you and others to help advance our mission. In exchange, we need you to make the following commitments:</p>
                    <div style={styles.indentedSection3}>
                        <p style={styles.text20White}>1. Who can use Portico</p>
                        <div style={styles.indentedSection3}>
                            <p style={styles.text20White}>When people stand behind their opinions and actions, our community is safer and more accountable. For this reason, you must:</p>
                            <div style={styles.indentedSection3}>
                                <p style={styles.text20White}>• Provide for your account the same name that you use in everyday life.</p> 
                                <p style={styles.text20White}>• Provide accurate information about yourself.</p> 
                                <p style={styles.text20White}>• Create only one account (your own) and use it for personal purposes.</p>
                                <p style={styles.text20White}>• Not share your password, give access to your Portico account to others, or transfer your account to anyone else (without our permission).</p>  
                            </div>
                            <p style={styles.text20White}>We try to make Portico broadly available to everyone, but you cannot use Portico if:</p>
                            <div style={styles.indentedSection3}>
                                <p style={styles.text20White}>• You are under 13 years old.</p>
                                <p style={styles.text20White}>• You are a convicted sex offender.</p>
                                <p style={styles.text20White}>• We've previously disabled your account for violations of our Terms or the Community Standards, or other terms and policies that apply to your use of Portico If we disable your account for a violation of our Terms, the Community Standards, or other terms and policies, you agree not to create another account without our permission. Receiving permission to create a new account is provided at our sole discretion, and does not mean or imply that the disciplinary action was wrong or without cause.</p>
                                <p style={styles.text20White}>• You are prohibited from receiving our products, services, or software under applicable laws.</p>
                            </div>
                        </div>

                        <p style={styles.text20White}>2. What you can share and do on One Portico, LLC Products</p>
                        <div style={styles.indentedSection3}>
                            <p style={styles.text20White}>We want people to use One Portico, LLC Products to express themselves and to share content that is important to them, but not at the expense of the safety and well-being of others or the integrity of our community. You therefore agree not to engage in the conduct described below (or to facilitate or support others in doing so):</p>
                            <div style={styles.indentedSection3}>
                                <p style={styles.text20White}>1. You may not use our Products to do or share anything:</p>
                                <div style={styles.indentedSection3}>
                                    <p style={styles.text20White}>• That violates these Terms, the Community Standards, or other terms and policies that apply to your use of our Products.</p>
                                    <p style={styles.text20White}>• That is unlawful, misleading, discriminatory or fraudulent (or assists someone else in using our Products in such a way).</p>
                                    <p style={styles.text20White}>• That you do not own or have the necessary rights to share.</p>
                                    <p style={styles.text20White}>• That infringes or violates someone else's rights, including their intellectual property rights.</p>
                                </div>
                                <p style={styles.text20White}>2. You may not upload viruses or malicious code, use the services to send spam, or do anything else that could disable, overburden, interfere with, or impair the proper working or appearance of our services.</p>
                                <p style={styles.text20White}>3. You may not access or collect data from our Products using automated means (without our prior permission) or attempt to access data you do not have permission to access.</p>
                                <p style={styles.text20White}>4. You may not misuse any reporting, flagging, dispute, or appeals channel.</p>
                            </div>
                            <p style={styles.text20White}>We can remove or restrict access to content that is in violation of these provisions. We can also suspend or disable your account for conduct that violates these provisions.</p>
                            <p style={styles.text20White}>If we remove content that you have shared in violation of the Community Standards, we’ll let you know and explain any options you have to request another review, unless you seriously or repeatedly violate these Terms or if doing so may expose us or others to legal liability; harm our community of users; compromise or interfere with the integrity or operation of any of our services; where we are restricted due to technical limitations; or where we are prohibited from doing so for legal reasons.</p>
                            <p style={styles.text20White}>To help support our community, we encourage you to report content or conduct that you believe violates your rights or our terms and policies.</p>
                            <p style={styles.text20White}>We also can remove or restrict access to content, features, services, or information if we determine that doing so is reasonably necessary to avoid or mitigate misuse of our services or adverse legal or regulatory impacts to One Portico, LLC.</p>
                        </div>

                        <p style={styles.text20White}>3. The permissions you give us</p>
                        <div style={styles.indentedSection3}>
                            <p style={styles.text20White}>We need certain permissions from you to provide our services:</p>
                            <div style={styles.indentedSection3}>
                                <p style={styles.text20White}>Permission to use content you create and share: Some content that you share or upload, such as photos or videos, may be protected by intellectual property laws.</p>
                                <p style={styles.text20White}>You retain ownership of the intellectual property rights (things like copyright or trademarks) in any such content that you create and share on One Portico, LLC and other One Portico, LLC Company Products you use. Nothing in these Terms takes away the rights you have to your own content. You are free to share your content with anyone else, wherever you want.</p>
                                <p style={styles.text20White}>However, to provide our services we need you to give us some legal permissions (known as a "license") to use this content. This is solely for the purposes of providing and improving our Products and services as described in Section 1 above.</p>
                                <p style={styles.text20White}>Specifically, when you share, post, or upload content that is covered by intellectual property rights on or in connection with our Products, you grant us a non-exclusive, transferable, sub-licensable, royalty-free, and worldwide license to host, use, distribute, modify, run, copy, publicly perform or display, translate, and create derivative works of your content (consistent with your privacy and application settings). This means, for example, that if you share a photo on One Portico, LLC, you give us permission to store, copy, and share it with others (again, consistent with your settings) such as One Portico, LLC Products or service providers that support those products and services. This license will end when your content is deleted from our systems.</p>
                                <p style={styles.text20White}>You can delete individual content you share, post, and upload at any time. In addition, all content posted to your personal account will be deleted if you delete your account. Learn more about how to delete your account. Account deletion does not automatically delete content that you post as an admin of a page or content that you create collectively with other users, such as photos in Shared Albums which may continue to be visible to other album members.</p>
                                <p style={styles.text20White}>It may take up to 90 days to delete content after we begin the account deletion process or receive a content deletion request. If you send content to trash, the deletion process will automatically begin in 30 days unless you chose to delete the content sooner. While the deletion process for such content is being undertaken, the content is no longer visible to other users. After the content is deleted, it may take us up to another 90 days to remove it from backups and disaster recovery systems.</p>
                                <p style={styles.text20White}>Content will not be deleted within 90 days of the account deletion or content deletion process beginning in the following situations:</p>
                                <div style={styles.indentedSection3}>
                                    <p style={styles.text20White}>• where your content has been used by others in accordance with this license and they have not deleted it (in which case this license will continue to apply until that content is deleted);</p>
                                    <p style={styles.text20White}>• where deletion within 90 days is not possible due to technical limitations of our systems, in which case, we will complete the deletion as soon as technically feasible; or</p>
                                    <p style={styles.text20White}>• where immediate deletion would restrict our ability to investigate or identify illegal activity or violations of our terms and policies, protect the safety, integrity, and security of our Products, comply with legal obligations for the preservation of evidence, or comply with a request of a judicial or administrative authority, law enforcement or a government agency;</p>
                                </div>
                                <p style={styles.text20White}>In each of the above cases, this license will continue until the content has been fully deleted.</p>
                                <p style={styles.text20White}>Permission to use your name, profile picture, and information about your actions with ads and sponsored or commercial content: You give us permission to use your name and profile picture and information about actions you have taken on One Portico, LLC next to or in connection with ads, offers, and other sponsored or commercial content that we display across our Products, without any compensation to you. For example, we may show your friends that you are interested in an advertised event or have liked a One Portico, LLC Page created by a brand that has paid us to display its ads. Ads like this can be seen only by people who have your permission to see the actions you've taken on One Portico, LLC Products. You can learn more about your ad settings and preferences.</p>
                                <p style={styles.text20White}>Permission to update software you use or download: If you download or use our software, you give us permission to download and install updates to the software where available.</p>
                            </div>
                        </div>

                        <p style={styles.text20White}>4. Limits on using our intellectual property</p>
                        <div style={styles.indentedSection3}>
                            <p style={styles.text20White}>If you use content covered by intellectual property rights that we have and make available in our Products (for example, images, designs, videos, or sounds we provide that you add to content you create or share), we retain all rights to that content (but not yours). You can only use our copyrights or trademarks (or any similar marks) as expressly permitted by our Brand Usage Guidelines or with our prior written permission. You must obtain our written permission (or permission under an open source license) to modify, translate, create derivative works of, decompile, or reverse engineer our products or their components, or otherwise attempt to extract source code from us, unless an exception or limitation applies under applicable law.</p>
                        </div>
                    </div>

                    <p style={styles.text20White}>3. Additional provisions</p>
                    <div style={styles.indentedSection3}>
                        <p style={styles.text20White}>1. Updating our Terms</p>
                        <div style={styles.indentedSection3}>
                            <p style={styles.text20White}>We work constantly to improve our services and develop new features to make our Products better for you and our community. As a result, we may need to update these Terms from time to time to accurately reflect our services and practices, to promote a safe and secure experience on our Products and services, and/or to comply with applicable law. Unless otherwise required by law, we will notify you before we make changes to these Terms and give you an opportunity to review them before they go into effect. Once any updated Terms are in effect, you will be bound by them if you continue to use our Products. We hope that you will continue using our Products, but if you do not agree to our updated Terms and no longer want to be a part of the One Portico, LLC community, you can delete your account at any time.</p>
                        </div>
                        <p style={styles.text20White}>2. Account suspension or termination</p>
                        <div style={styles.indentedSection3}>
                            <p style={styles.text20White}>We want Portico to be a place where people feel welcome and safe to express themselves and share their thoughts and ideas. If we determine, in our discretion, that you have clearly, seriously or repeatedly breached our Terms or Policies, we may suspend or permanently disable your access to One Portico, LLC Products, and we may permanently disable or delete your account. We may also disable or delete your account if you repeatedly infringe other people’s intellectual property rights or where we are required to do so for legal reasons.</p>
                            <p style={styles.text20White}>We may disable or delete your account if after registration your account is not confirmed, your account is unused and remains inactive for an extended period of time, or if we detect someone may have used it without your permission and we are unable to confirm your ownership of the account. Learn more about how we disable and delete accounts.</p>
                            <p style={styles.text20White}>Where we take such action we’ll let you know and explain any options you have to request a review, unless doing so may expose us or others to legal liability; harm our community of users; compromise or interfere with the integrity or operation of any of our services, systems or Products; where we are restricted due to technical limitations; or where we are prohibited from doing so for legal reasons.</p>
                            <p style={styles.text20White}>You can learn more about what you can do if your account has been disabled and how to contact us if you think we have disabled your account by mistake. If you delete or we disable or delete your account, these Terms shall terminate as an agreement between you and us, but the following provisions will remain in place: 3, 4.2-4.5.</p>
                        </div>

                        <p style={styles.text20White}>3. Limits on liability</p>
                        <div style={styles.indentedSection3}>
                            <p style={styles.text20White}>We work hard to provide the best Products we can and to specify clear guidelines for everyone who uses them. Our Products, however, are provided "as is," and we make no guarantees that they always will be safe, secure, or error-free, or that they will function without disruptions, delays, or imperfections. To the extent permitted by law, we also DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. We do not control or direct what people and others do or say, and we are not responsible for their actions or conduct (whether online or offline) or any content they share (including offensive, inappropriate, obscene, unlawful, and other objectionable content). We cannot predict when issues might arise with our Products. Accordingly, our liability shall be limited to the fullest extent permitted by applicable law, and under no circumstance will we be liable to you for any lost profits, revenues, information, or data, or consequential, special, indirect, exemplary, punitive, or incidental damages arising out of or related to these Terms or the One Portico, LLC Products (however caused and on any theory of liability, including negligence), even if we have been advised of the possibility of such damages. Our aggregate liability arising out of or relating to these Terms or the One Portico, LLC Products will not exceed the greater of $100 or the amount you have paid us in the past twelve months.</p>
                        </div>

                        <p style={styles.text20White}>4. Disputes</p>
                        <div style={styles.indentedSection3}>
                            <p style={styles.text20White}>We try to provide clear rules so that we can limit or hopefully avoid disputes between you and us. If a dispute does arise, however, it's useful to know up front where it can be resolved and what laws will apply.</p>
                            <p style={styles.text20White}>You and One Portico, LLC each agree that any claim, cause of action, or dispute between us that arises out of or relates to these Terms or your access or use of the One Portico, LLC Products shall be resolved exclusively in the U.S. District Court for the Northern District of California or a state court located in San Mateo County. You also agree to submit to the personal jurisdiction of either of these courts for the purpose of litigating any such claim, and that the laws of the State of California will govern these Terms and any claim, cause of action, or dispute without regard to conflict of law provisions. Without prejudice to the foregoing, you agree that, in its sole discretion, One Portico, LLC may bring any claim, cause of action, or dispute we have against you in any competent court in the country in which you reside that has jurisdiction over the claim.</p>
                        </div>

                        <p style={styles.text20White}>5. Other</p>
                        <div style={styles.indentedSection3}>
                            <p style={styles.text20White}>1. These Terms make up the entire agreement between you and One Portico, LLC regarding your use of our Products. They supersede any prior agreements.</p>
                            <p style={styles.text20White}>2. Some of the Products we offer are also governed by supplemental terms. If you use any of those Products, supplemental terms will be made available and will become part of our agreement with you. To the extent any supplemental terms conflict with these Terms, the supplemental terms shall govern to the extent of the conflict.</p>
                            <p style={styles.text20White}>3. If any portion of these Terms is found to be unenforceable, the unenforceable portion will be deemed amended to the minimum extent necessary to make it enforceable, and if it can’t be made enforceable, then it will be severed and the remaining portion will remain in full force and effect. If we fail to enforce any of these Terms, it will not be considered a waiver. Any amendment to or waiver of these Terms must be made in writing and signed by us.</p>
                            <p style={styles.text20White}>4. You will not transfer any of your rights or obligations under these Terms to anyone else without our consent.</p>
                            <p style={styles.text20White}>5. You may designate a person (called a legacy contact) to manage your account if it is memorialized. Only your legacy contact or a person who you have identified in a valid will or similar legal document expressing clear consent to disclose your content to that person upon death or incapacity will be able to seek limited disclosure of information from your account after it is memorialized.</p>
                            <p style={styles.text20White}>6. These Terms do not confer any third-party beneficiary rights. All of our rights and obligations under these Terms are freely assignable by us in connection with a merger, acquisition, or sale of assets, or by operation of law or otherwise.</p>
                            <p style={styles.text20White}>7. We may need to change the username for your account in certain circumstances (for example, if someone else claims the username and it appears unrelated to the name you use in everyday life).</p>
                            <p style={styles.text20White}>8. We always appreciate your feedback and other suggestions about our products and services. But we may use feedback and other suggestions without any restriction or obligation to compensate you, and we are under no obligation to keep them confidential.</p>
                            <p style={styles.text20White}>9. We reserve all rights not expressly granted to you.</p>
                        </div>
                    </div>
                </div>

                {/* <p style={styles.text20White}></p>
                <div style={styles.indentedSection3}></div>
                <p style={styles.text20White}>• </p> */}

                {/* <p style={styles.text20White}>Community Guidelines</p>
                <p style={styles.text15White}>Mission Statement</p> */}

                {/* <p style={styles.text20White}>Platform Policy</p>
                <p style={styles.text15White}>Consequences of Violation</p> */}

            </div>


        </div>
    );
}

export default TermsPage;

const styles = {
    containerParent: {
        // flex: 1,
        height: '100%',
        width: '100%',
        backgroundColor: '#1A1A1A',
    },
    indentedSection1: {
        padding: 40,
    },
    indentedSection2: {
        paddingLeft: 40,
    },
    indentedSection3: {
        paddingLeft: 40,
    },

    text40White: {
        fontWeight: '600',
        color: 'white', 
    },
    text20White: {
        color: 'white', 
    },
    text15White: {
        color: 'white', 
    },
    
}