import React from 'react';

function AboutPage() {
  return (
    <div>
      <h1>About Page</h1>
      <p>This is the about page.</p>
    </div>
  );
}

export default AboutPage;

