import React from 'react';


function PremiumPage() {
  const getResponsiveStyles = () => {
    const isMobile = window.innerWidth <= 768;

    const baseStyles = {
      parentContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
      },
      sectionContainer1: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        alignItems: 'center',
        // backgroundImage: `url(${BackgroundImage})`, 
        backgroundColor: '#000000', //002E4D
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
      },
      sectionContainer2: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        alignItems: 'center',
        backgroundColor: '#1A1A1A',
      },
      sectionContainer3: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        alignItems: 'center',
        backgroundColor: '#1A1A1A', //F2F2F2 //white //1A1A1A //162A4A
      },
      subsectionContainer: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        // width: '100%', //80
        width: isMobile ? '100%' : '75%',
        margin: 20,
      },
      largeImages: {
        width: isMobile ? '100%' : '100%', 
        height: 'auto',
      },
      largeImages2: {
        width: isMobile ? '100%' : '100%',
        height: 'auto',
      },
      buttonParent: {
        width: '50%',
      },
      containerButton: {
        backgroundColor: '#FF8C00',
        borderRadius: 15,
        borderWidth: 0,
        padding: 10,
      },
      containerImage: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        order: isMobile ? -1 : 1, // Image before text in mobile view
      },
      containerText: {
        flex: 1,
        rowGap: isMobile ? '12px' : '25px',
        display: 'flex',
        flexDirection: 'column',
        padding: isMobile ? '20px' : '40px',
        fontFamily: 'Impact, Charcoal, sans-serif',
        order: isMobile ? 0 : 1, // Text after image in desktop view
      },
      containerText2: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
        flexDirection: 'column',
        padding: isMobile ? '20px' : '40px',
        fontFamily: 'Impact, Charcoal, sans-serif',
        order: isMobile ? 0 : 1, // Same logic applied to this text container as well
      },

      textImpactWhite1: {
        color: 'white',
        fontSize: isMobile ? 30 : 60, //fontSize: isMobile ? 30 : 45,
        textAlign: 'center',
        fontFamily: 'Impact, Charcoal, sans-serif',
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      textImpactWhite2: {
        color: 'white',
        fontSize: isMobile ? 30 : 60, //fontSize: isMobile ? 30 : 45,
        textAlign: 'center',
        fontFamily: 'Impact, Charcoal, sans-serif',
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      textWhiteHeader: {
        color: 'white',
        fontFamily: 'sans-serif',
        fontSize: isMobile ? 15 : 25,
        textAlign: 'center',
        fontWeight: '500',
        margin: 0,
      },
      textWhite: {
        color: 'white',
        fontFamily: 'sans-serif',
        fontSize: isMobile ? 15 : 20,
        fontWeight: '500',
        margin: 0,
      },
      textImpactBlack2: {
        color: '#1a1a1a',
        fontSize: isMobile ? 30 : 60, //fontSize: isMobile ? 30 : 45,
        textAlign: 'center',
        fontFamily: 'Impact, Charcoal, sans-serif',
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      textBlack: {
        color: '#1a1a1a',
        fontFamily: 'sans-serif',
        fontSize: isMobile ? 15 : 25,
        fontWeight: '500',
        margin: 0,
      },
      buttonTextWhite: {
        color: 'white',
        fontFamily: 'sans-serif',
        fontSize: 20,
        
        fontWeight: '600',
        margin: 0,
      },
      
    };

    return baseStyles;
  };

  // Call the function to get the current styles
  const styles = getResponsiveStyles();

  return (
    <div style={styles.parentContainer} >

        <div style={styles.sectionContainer1} >
            <div style={styles.containerImage} >
                <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/paywallImage.png" alt='premiumImage' style={styles.largeImages} />
            </div>
            <div style={styles.containerText} >
                {/* <h1 style={styles.textImpactWhite1}>PORTICO PREMIUM</h1> */}
                <h1 style={styles.textImpactWhite1}>SAVE WITH</h1>
                <h1 style={styles.textImpactWhite1}>PORTICO PREMIUM</h1>
                <p style={styles.textWhiteHeader}>Online shopping how it should be.</p>
            </div>
        </div>
        
        <div style={styles.sectionContainer3} >
            <div style={styles.containerText2} >
                <div style={styles.subsectionContainer}>
                    <h1 style={styles.textImpactWhite2}>AI POWERED SHOPPING</h1>
                    <p style={styles.textWhite}>With Portico Premium, your shopping experience is optimized for value, not cluttered with endless ads or irrelevant content. Our AI-driven system ensures that you find exactly what you're looking for at the best possible price, turning every search into an opportunity for savings.</p>
                    <p style={styles.textWhite}>Our platform is your gateway to a world brimming with undiscovered brands and exclusive products and other hidden gems. Portico Premium makes it easy to explore new horizons in retail, bringing you closer to your next favorite find.</p>
                    <p style={styles.textWhite}>When you shop with Portico Premium, we use the power of artificial intellegence to deliver you results instead of ads.</p>
                </div>

                {/* <div style={styles.subsectionContainer}>
                    <h1 style={styles.textImpactWhite2}>AI POWERED SELLING</h1>
                    <p style={styles.textWhite}>Join the marketplace for your listings on other platforms. Upload your product listings from eBay, Redbubble, Etsy, Instagram, TikTok or Amazon on Portico and let Portico's product finding algorithm take care of the rest.</p>
                    <p style={styles.textWhite}>Portico's advanced AI analyzes the description and image of your product and recommends to users when they are looking at a product similar to what you sell.</p>
                </div> */}
            </div>
            <div style={styles.containerImage} >
                {/* <img src={PorticoPremiumSS} alt='premiumSSImage' style={styles.largeImages}/> */}
                <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/PorticoPremiumSS.png" alt='premiumSSImage' style={styles.largeImages} />
            </div>
        </div>

    </div>
    
  );
}

export default PremiumPage;
