import React from 'react';

function ShopPage() {
  // Define a function to dynamically change styles based on screen width
  const getResponsiveStyles = () => {
    const isMobile = window.innerWidth <= 768; // Example breakpoint at 768px

    const baseStyles = {
      parentContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
      },
      sectionContainer1: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        alignItems: 'center',
        // backgroundImage: `url(${BackgroundImage})`, 
        backgroundColor: '#1a1a1a', //393838(lightgray) //014173(blue) //1A1A1A //F2F2F2 //01457B //002E4D
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
      },
      sectionContainer2: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        alignItems: 'center',
        backgroundColor: '#1A1A1A',// backgroundColor: isMobile ? '#1A1A1A' : '#f2f2f2', //f2f2f2 //1A1A1A
      },
      sectionContainer3: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        alignItems: 'center',
        backgroundColor: '#1A1A1A', //162A4A
      },
      subsectionContainer: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        // width: '100%',
        width: isMobile ? '100%' : '75%',
        backgroundColor: '#1a1a1a', //f2f2f2
        borderRadius: isMobile ? 5 : 10,
        padding: isMobile ? 10 : 15,
        // margin: 20,
      },
      containerImage: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        order: isMobile ? -1 : 1, // Image before text in mobile view
      },
      containerText: {
        flex: 1,
        rowGap: isMobile ? '12px' : '25px',
        display: 'flex',
        flexDirection: 'column',
        padding: isMobile ? '20px' : '40px',
        fontFamily: 'Impact, Charcoal, sans-serif',
        order: isMobile ? 0 : 1, // Text after image in desktop view
      },
      containerText2: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
        flexDirection: 'column',
        padding: isMobile ? '20px' : '40px',
        fontFamily: 'Impact, Charcoal, sans-serif',
        order: isMobile ? 0 : 1, // Same logic applied to this text container as well
      },
      largeImages: {
        width: isMobile ? '100%' : '100%', 
        height: 'auto',
      },
      largeImages2: {
        width: isMobile ? '100%' : '100%',
        height: 'auto',
      },
      buttonParent: {
        width: '50%',
      },
      containerButton: {
        backgroundColor: '#FF8C00',
        borderRadius: 15,
        borderWidth: 0,
        padding: 10,
      },

      textImpactWhite1: {
        color: 'white',
        fontSize: isMobile ? 30 : 90, 
        textAlign: 'center',
        fontFamily: 'Impact, Charcoal, sans-serif', 
        transform: 'skewX(-20deg)', 
        margin: 0,
        overflow: 'hidden',
      },
      textImpactBlack1: {
        color: '#1A1A1A',
        fontSize: isMobile ? 30 : 110,
        fontFamily: 'Impact, Charcoal, sans-serif', 
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      textImpactWhite2: {
        color: 'white',
        fontSize: isMobile ? 30 : 90, //fontSize: isMobile ? 30 : 45,
        textAlign: 'center',
        fontFamily: 'Impact, Charcoal, sans-serif',
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      textImpactBlack2: {
        color: '#1a1a1a',
        fontSize: isMobile ? 30 : 90, //fontSize: isMobile ? 30 : 45,
        textAlign: 'center',
        fontFamily: 'Impact, Charcoal, sans-serif',
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      textWhite: {
        color: 'white',
        fontFamily: 'sans-serif',
        fontSize: isMobile ? 15 : 25,
        fontWeight: '500',
        margin: 0,
      },
      textWhiteCarrot: {
        color: 'white',
        fontFamily: 'sans-serif',
        fontSize: isMobile ? 30 : 90,
        textAlign: 'center',
        fontWeight: '500',
        margin: 0,
      },
      textBlack: {
        color: '#1A1A1A',
        fontFamily: 'sans-serif',
        fontSize: isMobile ? 15 : 25,
        fontWeight: '500',
        margin: 0,
      },
      buttonTextWhite: {
        color: 'white',
        fontFamily: 'sans-serif',
        fontSize: 20,
        
        fontWeight: '600',
        margin: 0,
      }
    };

    return baseStyles;
  };

  // Call the function to get the current styles
  const styles = getResponsiveStyles();

  return (
    <div style={styles.parentContainer} >

      <div style={styles.sectionContainer1} >
          <div style={styles.containerImage} >
            <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/PorticoShopPage1.png" alt='ShopImage1' style={styles.largeImages} />
            {/* <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/PorticoShop.png" alt='ShopImage' style={styles.largeImages} /> */}
          </div>
          <div style={styles.containerText} >
            <h1 style={styles.textImpactWhite1}>THE ULTIMATE</h1>
            <h1 style={styles.textImpactWhite1}>SHOPPING EXPERIENCE</h1>
            <p style={styles.textWhite}>Welcome to Portico, your one-stop online shopping companion designed to revolutionize the way you shop. With Portico, you're not just buying; you're embarking on a journey to smart savings, unparalleled convenience, and endless choices.</p>
            <p style={styles.textWhite}>Here’s what makes Portico stand out in the digital shopping landscape</p>
            <h1 style={styles.textWhiteCarrot}>⌄</h1>
          </div>
      </div>

      <div style={styles.sectionContainer2} >
        <div style={styles.containerText2} >
          <div style={styles.subsectionContainer}>
            <h1 style={styles.textImpactWhite2}>ALL YOUR FAVORITE</h1>
            <h1 style={styles.textImpactWhite2}>STORES IN ONE</h1>
            <p style={styles.textWhite}>Forget tab-switching and bookmarking dozens of websites. Portico brings a seamless shopping experience by aggregating products from several stores all at once.</p>
            <p style={styles.textWhite}>Our intuitive platform allows you to browse, compare, and purchase from different retailers without leaving the comfort of your home.</p>
            <p style={styles.textWhite}>Reverse image search not finding the no-name brands at other stores? Portico uses an advancing AI to detect features of a product to find you high-quality knock-offs and budget-friendly alternatives.</p>
            <p style={styles.textWhite}>Find the cheapest prices on replicas that rival the originals in quality and style. It's a smart way to enjoy luxury without the hefty price tag.</p>
          </div>
        </div>
        <div style={styles.containerImage} >
          <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/PorticoShopPage2.png" alt='ShopImage2' style={styles.largeImages} />
        </div>
      </div>

    </div>
  );
}

export default ShopPage;