import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import HomeBackground1 from './assets/homeBackground1.png';

function DiscoverAuthenticityPage() {
  const getResponsiveStyles = () => {
    const isMobile = window.innerWidth <= 768;

    const baseStyles = {
      parentContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
      },
      sectionContainer1: {
        display: 'flex',
        // flexDirection: isMobile ? 'column' : 'row',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        backgroundColor: '#1a1a1a', //white //1a1a1a
        backgroundImage: `url(${HomeBackground1})`,
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
        paddingBottom: isMobile ? 300 : 0,
      },
      sectionContainer2: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        alignItems: 'center',
        backgroundColor: '#1A1A1A',
        zIndex: 3,
      },
      sectionContainer3: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        alignItems: 'center',
        backgroundColor: '#1A1A1A',
      },
      subsectionContainer: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: isMobile ? '100%' : '75%',
        backgroundColor: '#1A1A1A',
      },
      subsectionTitle: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: isMobile ? '100%' : '75%',
        backgroundColor: '#1A1A1A',
      },
      svgContainer: {
        width: '100%',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      },
      svgIcons: {
        width: isMobile ? '10%' : '10%',
        margin: 0,
        padding: 0,
      },
      largeImages: {
        width: '100%', 
        objectFit: 'cover',
        // marginBottom: 20,
      },
      headerImage: {
        width: '100%', 
        objectFit: 'cover',
        marginBottom: 20,
        zIndex: -2,
      },
      largeImages2: {
        width: isMobile ? '100%' : '100%',
        height: 'auto',
      },
      buttonParentContainer: {
        width: '100%',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px 0',
      },
      motionImage1: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        backgroundColor: 'transparent', //white //1a1a1a
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
      },
      appStoreButtonParentContainer: {
        position: 'absolute',
        bottom: isMobile ? '50px' : '50px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: isMobile ? 'auto' : '100%',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 3,
        cursor: 'pointer',
      },
      buttonParent: {},
      containerButton: {
        backgroundColor: '#FF8C00',
        borderRadius: 50,
        borderWidth: 0,
        padding: "10px 50px 10px 50px",
        cursor: 'pointer',
      },

      containerImage: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        order: isMobile ? -1 : 1,
      },
      // containerImageSection1: {
      //   width: isMobile ? 'auto' : '40%',
      //   height: isMobile ? 'auto' : '70vh',
      //   position: 'static', 
      // },
      containerImageSection1: {
        // width: isMobile ? 'auto' : '40%',
        // height: isMobile ? 'auto' : '70vh',
        // position: 'static', 
        width: isMobile ? 'auto' : '45%', //width: isMobile ? 'auto' : '40%',
        height: isMobile ? '35vh' : '68vh', //height: isMobile ? 'auto' : '70vh',
        position: 'relative', //static
      },
      containerText: {
        flex: 1,
        rowGap: isMobile ? '12px' : '25px',
        display: 'flex',
        flexDirection: 'column',
        padding: isMobile ? '20px' : '40px',
        fontFamily: 'Impact, Charcoal, sans-serif',
        order: isMobile ? 0 : 1,
      },
      containerTextSection1: {
        flex: 1,
        rowGap: isMobile ? '12px' : '25px',
        display: 'flex',
        flexDirection: 'column',
        padding: isMobile ? '20px' : '40px',
        paddingTop: isMobile ? 70 : 70,
        fontFamily: 'Impact, Charcoal, sans-serif',
        zIndex: 2,
        // order: isMobile ? 0 : 1,
      },
      containerText2: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
        flexDirection: 'column',
        padding: isMobile ? '20px' : '40px',
        fontFamily: 'Impact, Charcoal, sans-serif',
        order: isMobile ? 0 : 1,
        // order: isMobile ? 1 : 0, //image first
      },

      textImpactWhite1: {
        color: 'white',
        fontSize: isMobile ? 45 : 110,
        textAlign: 'center',
        fontFamily: 'Impact, Charcoal, sans-serif', 
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      textImpactBlack1: {
        color: '#1A1A1A',
        fontSize: isMobile ? 45 : 110,
        textAlign: 'center',
        fontFamily: 'Impact, Charcoal, sans-serif', 
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      textImpactWhite2: {
        color: 'white',
        fontSize: isMobile ? 30 : 60,
        textAlign: 'center',
        fontFamily: 'Impact, Charcoal, sans-serif',
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      textImpactBlack2: {
        color: '#1a1a1a',
        fontSize: isMobile ? 30 : 90,
        textAlign: 'center',
        fontFamily: 'Impact, Charcoal, sans-serif',
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      textWhiteHeader: {
        color: 'white',
        fontFamily: 'sans-serif',
        fontSize: isMobile ? 15 : 25,
        fontWeight: '500',
        margin: 0,
        textAlign: 'center',
      },
      textWhiteHeader2: {
        color: 'white',
        fontFamily: 'sans-serif',
        fontSize: isMobile ? 15 : 25,
        fontWeight: '500',
        margin: 0,
        padding: 0,
        textAlign: 'center',
      },
      textBlackHeader: {
        color: 'black',
        fontFamily: 'sans-serif',
        fontSize: isMobile ? 15 : 25,
        fontWeight: '500',
        margin: 0,
        textAlign: 'center',
      },
      textWhiteLarge: {
        color: 'white',
        fontFamily: 'sans-serif',
        // fontSize: isMobile ? 25 : 40,
        // fontSize: isMobile ? 20 : 35,
        fontSize: isMobile ? 15 : 30,
        fontWeight: '800',
        margin: 0,
      },
      textWhite: {
        color: 'white',
        fontFamily: 'sans-serif',
        // fontSize: isMobile ? 15 : 25,
        fontSize: isMobile ? 15 : 20,
        fontWeight: '100',
        margin: 0,
      },
      textBlack: {
        color: 'black',
        fontFamily: 'sans-serif',
        fontSize: isMobile ? 15 : 25,
        fontWeight: '500',
        margin: 0,
      },
      buttonTextWhite: {
        color: 'white',
        width: '100%',
        fontFamily: 'sans-serif',
        fontSize: isMobile ? 15 : 20,
        fontWeight: '600',
        margin: 0,
      },

      sectionContainer20: {
        // paddingTop: isMobile ? '100px' : '150px', 
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        alignItems: 'center',
        backgroundColor: '#1a1a1a', //f2f2f2 //1A1A1A
      },
      containerText20: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
        flexDirection: 'column',
        padding: isMobile ? '20px' : '40px',
        fontFamily: 'Impact, Charcoal, sans-serif',
        // order: isMobile ? 0 : 1, // Same logic applied to this text container as well
      },
      subsectionContainer20: {
        flex: 1,
        // rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        // width: '100%',
        width: isMobile ? '80%' : '100%',
      },
      sectionContainer201: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        // width: '100%',
        width: isMobile ? '80%' : '100%',
        backgroundColor: '#393838' 
      },
      sectionContainer20o: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        // width: '100%',
        width: isMobile ? '100%' : '100%',
        backgroundColor: '#FFA501' //
      },
      textImpactWhite20: {
        color: 'white',
        // fontSize: isMobile ? 30 : 90, //fontSize: isMobile ? 30 : 45,
        fontSize: isMobile ? 30 : 60, //fontSize: isMobile ? 30 : 45,
        textAlign: 'left', //center
        fontFamily: 'Impact, Charcoal, sans-serif',
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      textImpactWhite20C: {
        color: 'white',
        // fontSize: isMobile ? 30 : 90, //fontSize: isMobile ? 30 : 45,
        fontSize: isMobile ? 30 : 60, //fontSize: isMobile ? 30 : 45,
        textAlign: 'center', //center
        fontFamily: 'Impact, Charcoal, sans-serif',
        transform: 'skewX(-20deg)', 
        margin: 0,
      },
      sectionContainer30: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        alignItems: 'center',
        backgroundColor: '#1A1A1A', //1A1A1A //f2f2f2 // //162A4A
      },
      containerImage30: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
      },
      containerText30: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
        flexDirection: 'column',
        padding: isMobile ? '20px' : '40px',
        fontFamily: 'Impact, Charcoal, sans-serif',
      },
      subsectionContainer0: {
        flex: 1,
        rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: isMobile ? '100%' : '75%',
        // backgroundColor: 'white', //1a1a1a //f2f2f2
        borderRadius: isMobile ? 5 : 10,
        padding: isMobile ? 10 : 15, //padding: isMobile ? 10 : 50,
      },
    };

    return baseStyles;
  };

  const styles = getResponsiveStyles();

  // const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });
  const [ref4, inView4] = useInView({ triggerOnce: true });

  const imageVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } }
  };

  return (


    <div style={styles.parentContainer} >
      <div style={styles.sectionContainer1} >
        <div style={styles.containerTextSection1} >
          {/* <h1 style={styles.textImpactWhite1}>SOCIAL COMMERCE</h1> */}
          <h1 style={styles.textImpactWhite1}>INVEST IN CONVENIENCE</h1>
          {/* <p style={styles.textWhiteHeader}>Shop with direction.</p> */}
          {/* <div>
            <p style={styles.textWhiteHeader2}>Invest in Convenience.</p>
          </div> */}
        </div>

        {/* <p style={styles.textWhite}>Portico takes the risk out of the buying process by making product research more convenient. Join the growing community of savvy shoppers who are using a new method of search to make better decisions.</p> */}
        <motion.div style={styles.motionImage1} initial="hidden" animate="visible" variants={imageVariants}>
          <div style={styles.containerImageSection1} >
            <motion.img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/socialImagePageMain.png" alt='topImage' style={styles.headerImage} />
          </div>
        </motion.div>
      </div>

 
      <motion.div ref={ref2} style={styles.sectionContainer2} initial="hidden" animate={inView2 ? "visible" : "hidden"} variants={imageVariants}>
        <div style={styles.containerText2} >
          <div style={styles.subsectionContainer}>
            {/* <p style={styles.textWhiteLarge}>Connect in a New Way</p> */}
            <p style={styles.textImpactWhite2}>Connect in a New Way</p>
            <p style={styles.textWhite}>It has never been easier to get recommendations, learn novel tips and tricks, and see what's good. Portico supplements the shopping experience with an engaged community that is dedicated to authentic reviews and candid recommendations.</p>
            <p style={styles.textWhite}>Be more informed and share lists, products, images, and links with your community.</p>
          </div>
        </div>
        <div style={styles.containerImage} >
          {/* <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/homeImage2.png" alt='ShopImage' style={styles.largeImages} /> */}
          {/* <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/findSimilarImage.png" alt='ShopImage' style={styles.largeImages} /> */}
          <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/connectInANewWay.png" alt='ShopImage' style={styles.largeImages} />
        </div>
      </motion.div>

      <motion.div ref={ref3} initial="hidden" animate={inView3 ? "visible" : "hidden"} variants={imageVariants}>
        <div style={styles.sectionContainer20o} >
          <div style={styles.containerText20} >
            <div style={styles.subsectionContainer20}>
              <h1 style={styles.textImpactWhite20C}>COLLABORATE WITH FRIENDS</h1>
            </div>
          </div>
        </div> 
        <div style={styles.sectionContainer30} >
          <div style={styles.containerImage30} >
            <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/listViewX5.png" alt='ShopImage2' style={styles.largeImages} />
          </div>
        </div>
      </motion.div>

      <motion.div ref={ref4} style={styles.sectionContainer3} initial="hidden" animate={inView4 ? "visible" : "hidden"} variants={imageVariants}>
        <div style={styles.containerImage} >
          <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/v2/createLists.png" alt='ConnectImage' style={styles.largeImages} />
        </div>
        <div style={styles.containerText2} >
          <div style={styles.subsectionContainer}>
            {/* <p style={styles.textWhiteLarge}>Create Smart Lists</p> */}
            <p style={styles.textImpactWhite2}>Create Smart Lists</p>
            <p style={styles.textWhite}>Creating your shopping lists with the notes app? Planning what to buy with a list can keep you focused and help you save money.</p>
            <p style={styles.textWhite}>Portico Lists were designed to make it easier to save while you shop. Compile products and items into one list and easily use Portico's Smart Search to discover similar products and easily identify the best place to buy.</p>

            {/* <p style={styles.textWhite}>Create Smart Lists and conveniently share them with your friends to plan a prodject, a party, or a trip. With Portico, it has never been easier to put your thoughts together in one place and share your plans with the world.</p> */}
            
            <div style={styles.buttonParentContainer}>
              <div style={styles.buttonParent}>
                <Link to="/searchandshop" style={{ textDecoration: 'none' }}>
                  <button style={styles.containerButton}>
                    <p style={styles.buttonTextWhite}>Learn More</p>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

    </div>
  );
}

export default DiscoverAuthenticityPage;










// import React from 'react';

// function DiscoverAuthenticityPage() {
//   // Define a function to dynamically change styles based on screen width
//   const getResponsiveStyles = () => {
//     const isMobile = window.innerWidth <= 768; // Example breakpoint at 768px

//     const baseStyles = {
//       parentContainer: {
//         display: 'flex',
//         flexDirection: 'column',
//         width: '100%',
//         height: '100%',
//         backgroundColor: 'black',
//       },
//       sectionContainer1: {
//         display: 'flex',
//         flexDirection: isMobile ? 'column' : 'row',
//         width: '100%',
//         alignItems: 'center',
//         // backgroundImage: `url(${BackgroundImage})`, 
//         backgroundColor: '#1A1A1A', //F2F2F2 //01457B //002E4D
//         backgroundSize: 'cover', 
//         backgroundPosition: 'center',
//       },
//       sectionContainer2: {
//         display: 'flex',
//         flexDirection: isMobile ? 'column' : 'row',
//         width: '100%',
//         alignItems: 'center',
//         backgroundColor: '#f2f2f2', //1A1A1A
//       },
//       sectionContainer3: {
//         display: 'flex',
//         flexDirection: isMobile ? 'column' : 'row',
//         width: '100%',
//         alignItems: 'center',
//         backgroundColor: '#1A1A1A', //162A4A
//       },
//       subsectionContainer: {
//         flex: 1,
//         rowGap: isMobile ? '10px' : '15px',
//         display: 'flex',
//         flexDirection: 'column',
//         height: '100%',
//         width: isMobile ? '100%' : '85%', //80 //width: '100%', //80
//         margin: 20,
//       },
//       subsectionContainer1: {
//         flex: 1,
//         rowGap: isMobile ? '10px' : '15px',
//         display: 'flex',
//         flexDirection: 'column',
//         height: '100%',
//         width: isMobile ? '100%' : '80%', //80 //width: '100%', //80
//         // margin: 20,
//       },
//       subsectionContainer2: {
//         flex: 1,
//         rowGap: isMobile ? '10px' : '15px',
//         display: 'flex',
//         flexDirection: 'column',
//         height: '100%',
//         width: isMobile ? '100%' : '75%', //80 //width: '100%', //80
//         // margin: 20,
//       },
//       containerImage: {
//         flex: 1,
//         display: 'flex',
//         justifyContent: 'center',
//         order: isMobile ? -1 : 1, // Image before text in mobile view
//       },
//       containerText: {
//         flex: 1,
//         rowGap: isMobile ? '12px' : '25px',
//         display: 'flex',
//         flexDirection: 'column',
//         padding: isMobile ? '20px' : '40px',
//         fontFamily: 'Impact, Charcoal, sans-serif',
//         order: isMobile ? 0 : 1, // Text after image in desktop view
//       },
//       containerText2: {
//         flex: 1,
//         rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         alignContent: 'center',
//         height: '100%',
//         flexDirection: 'column',
//         padding: isMobile ? '20px' : '40px',
//         fontFamily: 'Impact, Charcoal, sans-serif',
//         order: isMobile ? 0 : 1, // Same logic applied to this text container as well
//       },
//       subContainerText2: {
//         flex: 1,
//         // rowGap: isMobile ? '10px' : '15px', //rowGap: isMobile ? '10px' : '15px',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         alignContent: 'center',
//         height: '100%',
//         flexDirection: 'column',
//         padding: isMobile ? '20px' : '40px',
//         fontFamily: 'Impact, Charcoal, sans-serif',
//         order: isMobile ? 0 : 1, // Same logic applied to this text container as well
//       },
//       svgContainer: {
//         width: '100%',
//         display: 'flex',
//         alignContent: 'center',
//         alignItems: 'center',
//         justifyContent: 'center',
//       },
//       svgIcons: {
//         width: isMobile ? '10%' : '10%',
//         margin: 0,
//         padding: 0,
//         // backgroundColor: 'red'
//       },
//       largeImages: {
//         width: isMobile ? '100%' : '100%', 
//         height: 'auto',
//       },
//       largeImages2: {
//         width: isMobile ? '100%' : '100%',
//         height: 'auto',
//       },
//       buttonParent: {
//         width: '50%',
//       },
//       containerButton: {
//         backgroundColor: '#FF8C00',
//         borderRadius: 15,
//         borderWidth: 0,
//         padding: 10,
//       },

//       textImpactWhite1: {
//         color: 'white',
//         fontSize: isMobile ? 30 : 110,
//         textAlign: 'center',
//         fontFamily: 'Impact, Charcoal, sans-serif', 
//         transform: 'skewX(-20deg)', 
//         margin: 0,
//       },
//       textImpactBlack1: {
//         color: '#1A1A1A',
//         fontSize: isMobile ? 30 : 110,
//         textAlign: 'center',
//         fontFamily: 'Impact, Charcoal, sans-serif', 
//         transform: 'skewX(-20deg)', 
//         margin: 0,
//       },
//       textImpactWhite2: {
//         color: 'white',
//         fontSize: isMobile ? 30 : 60, // fontSize: isMobile ? 30 : 45,
//         textAlign: 'center',
//         fontFamily: 'Impact, Charcoal, sans-serif',
//         transform: 'skewX(-20deg)', 
//         margin: 0,
//       },
//       textImpactBlack2: {
//         color: '#1a1a1a',
//         fontSize: isMobile ? 30 : 60, // fontSize: isMobile ? 30 : 45,
//         textAlign: 'center',
//         fontFamily: 'Impact, Charcoal, sans-serif',
//         transform: 'skewX(-20deg)', 
//         margin: 0,
//       },
//       textWhite: {
//         color: 'white',
//         fontFamily: 'sans-serif',
//         fontSize: isMobile ? 15 : 25,
//         fontWeight: '500',
//         margin: 0,
//       },
//       textBlackLarge: {
//         color: '#1A1A1A',
//         fontFamily: 'sans-serif',
//         fontSize: isMobile ? 25 : 35,
//         textAlign: 'center',
//         fontWeight: '800',
//         margin: 0,
//       },
//       textWhiteLarge: {
//         color: 'white',
//         fontFamily: 'sans-serif',
//         fontSize: isMobile ? 25 : 35,
//         textAlign: 'center',
//         fontWeight: '800',
//         margin: 0,
//       },
//       textBlack: {
//         color: '#1A1A1A',
//         fontFamily: 'sans-serif',
//         fontSize: isMobile ? 15 : 25,
//         fontWeight: '500',
//         margin: 0,
//       },
//       buttonTextWhite: {
//         color: 'white',
//         fontFamily: 'sans-serif',
//         fontSize: 20,
//         fontWeight: '600',
//         margin: 0,
//       }
//     };

//     return baseStyles;
//   };

//   // Call the function to get the current styles
//   const styles = getResponsiveStyles();

//   return (
//     <div style={styles.parentContainer} >
      
//         {/* <div style={styles.sectionContainer3} >
//             <div style={styles.containerText2} >
//                 <div style={styles.subsectionContainer}>
//                     <h1 style={styles.textImpactWhite2}>BUILD YOUR BRAND</h1>
//                     <p style={styles.textWhite}>In today's digital marketplace, standing out is more important than ever. At Portico, we offer a dynamic platform designed not just to showcase your products but to catapult your brand into the spotlight.</p>
//                     <p style={styles.textWhite}>Maximize your product visibility by uploading your product listings to Portico and watch your brand's visibility soar. </p>
//                     <p style={styles.textWhite}>Share your products through posts that tell your story, highlight unique features, and showcase the benefits of choosing your brand.</p>
//                     <p style={styles.textWhite}>Amplify your reach with easily shareable links to your Portico listings and online store fronts. Drive traffic directly to your product pages, where customers can learn more, engage with your brand, and make purchases seamlessly.</p>
//                     <p style={styles.textWhite}>Create a vibrant community around your brand by engaging directly with customers and other sellers on Portico. Post updates, respond to comments, and participate in discussions to build relationships and trust. Your active presence and responsiveness can turn casual browsers into loyal customers and brand advocates.</p>
//                 </div>
//             </div>
//             <div style={styles.containerImage} >
//                 <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/PorticoConnect.png" alt='ConnectImage' style={styles.largeImages} />
//             </div>
//         </div> */}
//         <div style={styles.sectionContainer1} >
//             <div style={styles.containerImage} >
//                 {/* <img src={PorticoBrandBuild} alt='BuildYourBrandImage' style={styles.largeImages}/> */}
//                 <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/PorticoBrandBuild.png" alt='BuildYourBrandImage' style={styles.largeImages} />
//             </div>
//             <div style={styles.containerText2} >
//                 <div style={styles.subsectionContainer}>
//                     <h1 style={styles.textImpactWhite1}>DISCOVER</h1>
//                     <h1 style={styles.textImpactWhite1}>AUTHENTICITY</h1>
//                     <p style={styles.textWhite}></p>
//                 </div>
//             </div>
//         </div>

//         <div style={styles.sectionContainer2} >
//             <div style={styles.containerText2} >
              
//               <div style={styles.subsectionContainer1}>
//                   <div style={styles.svgContainer} >
//                     <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/pioneer.svg" alt='pioneerYourBrand' style={styles.svgIcons} />
//                   </div>
//                   <p style={styles.textImpactBlack2}>PIONEER YOUR BRAND</p>
//                   <p style={styles.textBlack}>Seize the opportunity to be among the first to establish your brand on Portico, the cutting-edge destination for online shopping. This isn't just about being present; it's about leading the way and setting the standard for what your business can sell online.</p>
//                   {/* <p style={styles.textBlack}>Pioneer Your Brand’s Online Presence: Seize the opportunity to be among the first to establish your brand on Portico, the cutting-edge destination for online shopping. This isn't just about being present; it's about leading the way and setting the standard for what your business can sell online.</p> */}
//                   <div style={styles.svgContainer} >
//                     <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/tailor.svg" alt='pioneerYourBrand' style={styles.svgIcons} />
//                   </div>
//                   <p style={styles.textImpactBlack2}>TAILOR YOUR NARRATIVE</p>
//                   <p style={styles.textBlack}>Break free from the constraints of conventional platforms. With Portico, you have the creative freedom to design your page in a way that truly reflects your brand’s ethos and aesthetic. Here, your business doesn’t have to conform — it stands out.</p>
//                   {/* <p style={styles.textBlack}>Tailor Your Brand’s Narrative: Break free from the constraints of conventional platforms. With Portico, you have the creative freedom to design your page in a way that truly reflects your brand’s ethos and aesthetic. Here, your business doesn’t have to conform — it stands out.</p> */}
//               </div>
//             </div>
//             <div style={styles.subContainerText2} >
//                 <div style={styles.subsectionContainer1}>
//                     <div style={styles.svgContainer} >
//                       <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/growth.svg" alt='pioneerYourBrand' style={styles.svgIcons} />
//                     </div>
//                     <p style={styles.textImpactBlack2}>FOCUS ON GROWTH</p>
//                     <p style={styles.textBlack}>Forget about crafting content to fit the latest viral trends. On Portico, your products are your voice. Build brand recognition and loyalty by showcasing the quality and uniqueness of what you offer, not through fleeting engagement tactics.</p>
//                     {/* <p style={styles.textBlack}>Focus on Authentic Brand Growth: Forget about crafting content to fit the latest viral trends. On Portico, your products are your voice. Build brand recognition and loyalty by showcasing the quality and uniqueness of what you offer, not through fleeting engagement tactics.</p> */}
//                     <div style={styles.svgContainer} >
//                       <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/focus.svg" alt='pioneerYourBrand' style={styles.svgIcons} />
//                     </div>
//                     <p style={styles.textImpactBlack2}>PRODUCT FOCUSED CONTENT</p>
//                     <p style={styles.textBlack}>Simplify the discovery process for your audience. By uploading your products and featuring them in posts, you make it easier for shoppers to find and fall in love with what you have to offer. Portico bridges the gap between product listing and storytelling to ensure that your products are not just seen by consumers, but remembered, saved, and added to their lists.</p>
//                     {/* <p style={styles.textBlack}>Seamlessly Connect Products and Content: Simplify the discovery process for your audience. By uploading your products and featuring them in posts, you make it easier for shoppers to find and fall in love with what you have to offer. Portico bridges the gap between product listing and storytelling to ensure that your products are not just seen by consumers, but remembered, saved, and added to their lists.</p> */}
//                 </div>
//             </div>
//         </div>
      
//         <div style={styles.sectionContainer3} >
//             <div style={styles.subContainerText2} >
//                 <div style={styles.subsectionContainer}>
//                     <h1 style={styles.textImpactWhite2}>UPLOAD PRODUCTS</h1>
//                     <p style={styles.textWhite}>Portico isn't just about transactions; it's about transformation — turning creators into trendsetters, and products into movements.</p>
//                     <p style={styles.textWhite}>When you join Portico, you're joing a community where creators with real talent can grow, go viral, and build their audience using the products the sell, not the quality of the posts they make.</p>
//                     {/* <p style={styles.textWhite}>Join an Elite Marketplace: When you introduce your products to Portico, you're stepping into an eclectic and prestigious marketplace alongside renowned retailers. On Portico, you can showcase your creations to a discerning audience eager for something new and exceptional.</p>
//                     <p style={styles.textWhite}>Smart Exposure Through Technology: Your products are not just listed in a database; they're actively being analyzed and discovered. Portico's sophisticated algorithm ensures your items are recommended to shoppers who have shown interest in similar products, which significantly increases the likelihood of discovery and purchase.</p> */}
//                     <p style={styles.textWhite}>Portico was designed to help its users discover new brands, stores, and products you might not otherwise be able to find. Here, it's not just about posting and creating "content"; it's about your brand becoming a part of someone's life, one extraordinary product at a time.</p>
//                 </div>
//             </div>
//             <div style={styles.containerImage} >
//                 {/* <img src={PorticoProduct} alt='ProductImage' style={styles.largeImages}/> */}
//                 <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/PorticoProduct.png" alt='ProductImage' style={styles.largeImages} />
//             </div>
//         </div>

//         <div style={styles.sectionContainer3} >
//             <div style={styles.subContainerText2} >
//                 <div style={styles.subsectionContainer2}>
//                     <div style={styles.svgContainer} >
//                       <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/elite.svg" alt='pioneerYourBrand' style={styles.svgIcons} />
//                     </div>
//                     <p style={styles.textImpactWhite2}>JOIN AN ELITE MARKETPLACE</p>
//                     <p style={styles.textWhite}>When you introduce your products to Portico, you're stepping into an eclectic and prestigious marketplace alongside renowned retailers. On Portico, you can showcase your creations to a discerning audience eager for something new and exceptional.</p>
//                 </div>
//             </div>
//             <div style={styles.subContainerText2} >
//                 <div style={styles.subsectionContainer2}>
//                   <div style={styles.svgContainer} >
//                     <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/smart.svg" alt='pioneerYourBrand' style={styles.svgIcons} />
//                   </div>
//                   <p style={styles.textImpactWhite2}>SMART EXPOSURE TECH</p>
//                   <p style={styles.textWhite}>Your products are not just listed in a database; they're actively being analyzed and discovered. Portico's sophisticated algorithm ensures your items are recommended to shoppers who have shown interest in similar products, which significantly increases the likelihood of discovery and purchase.</p>
//                 </div>
//             </div>
//         </div>

//       <div style={styles.sectionContainer3} >
//         <div style={styles.containerImage} >
//             <img src="https://porticowebsite.s3.us-east-2.amazonaws.com/images/PorticoPost.png" alt='PostImage' style={styles.largeImages} />
//         </div>
//         <div style={styles.containerText2} >
//             <div style={styles.subsectionContainer}>
//                 <h1 style={styles.textImpactWhite2}>SHARE YOUR LISTINGS</h1>
//                 <p style={styles.textWhite}>Building a media presense online should be centered on the products you sell, not the trendy dances you'll do just to get likes on a post.</p>
//                 <p style={styles.textWhite}>Streamline Product Discovery by seamlessly integrating them into your posts on Portico's social platform. This direct approach not only simplifies the shopping experience for your followers but also highlights your products as the stars of your brand's narrative.</p>
//                 <p style={styles.textWhite}>Authentic brand presence is built on the substance of what you offer, not fleeting trends or viral challenges. Portico redefines online engagement by putting your products at the heart of your social media strategy.</p>
//             </div>

//             <div style={styles.subsectionContainer}>
//                 <h1 style={styles.textImpactWhite2}>SHARE YOUR LINKS</h1>
//                 <p style={styles.textWhite}>Beyond direct listings, sharing links to blogs, news articles, or other relevant content about your brand or industry sparks meaningful conversations. This strategy keeps your audience engaged, informed, and excited about your brand, contributing to a sustained buzz and a stronger community connection.</p>
//                 <p style={styles.textWhite}> Utilize Portico as a platform to not just sell but to tell your story. Share insights, achievements, and developments that resonate with your audience. This approach fosters a deeper connection with your followers, turning them into loyal customers and brand advocates.</p>
//                 <p style={styles.textWhite}>Post links to blogs, news, or other media about your brand or industry to create conversation and keep your brand's buzz going strong.</p>
//             </div>
//         </div>
//     </div>

//     </div>
//   );
// }

// export default DiscoverAuthenticityPage;